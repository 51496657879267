import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from '@monite/ui';

import { useProjects } from 'features/projects/api';

import Layout, { PageHeader, PageContent } from 'features/app/Layout';
import Project from 'features/projects/Project';

import styles from './styles.module.scss';

const PageProjects = () => {
  const { t } = useTranslation();
  const { state } = useLocation() as any;

  const { isLoading, data: projects } = useProjects();

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Layout title={t('projects:list.title')} loading={isLoading}>
      <Tooltip />
      <PageHeader title={t('projects:list.title')}></PageHeader>
      <PageContent>
        <div className={styles.projects}>
          {(projects || []).map((project) => (
            <Project
              key={project.id}
              data={project}
              isHighlight={state?.highlightProjectId === project.id}
            />
          ))}
        </div>
      </PageContent>
    </Layout>
  );
};

export default PageProjects;
