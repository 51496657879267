import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Text, Button, Radio, FormField, Select } from '@monite/ui';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { format, add, differenceInSeconds } from 'date-fns';
import Modal from 'features/app/Modals/Modal';
import { revokeApiKey } from 'features/apikeys/api';

import styles from './styles.module.scss';
import { useModalContext } from '../../app/Modals/ModalContext';

const RevokeApiKeyModal: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();
  const { setModal } = useModalContext();
  const [steps, setSteps] = useState<'be_aware_message' | 'revoke'>(
    'be_aware_message'
  );
  const [resetOption, setResetOption] = useState(0);
  const [dateValue, setDateValue] = useState<Date>();

  const queryClient = useQueryClient();

  const revokeApiKeyMutation = useMutation({
    mutationFn: ({ id, delay }: { id: string; delay?: number }) =>
      revokeApiKey(id, delay),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['apiKeys'] });
      toast(t('apikeys:revoke.success'));
    },
    onError: () => {
      toast.error(t('common:somethingWrong'));
    },
  });

  const onChangeRevokeOption = (e: React.BaseSyntheticEvent) => {
    setResetOption(parseInt(e.target.value, 10));
  };

  const onClickCancel = () => {
    setModal(null);
  };

  const countDelay = (dateDelay: Date) =>
    differenceInSeconds(dateDelay, new Date());

  const onClickRevoke = () => {
    const delay = countDelay(dateValue as Date);

    revokeApiKeyMutation.mutate({ id, delay });
    setModal(null);
  };

  const inOneHour = add(Date.now(), { hours: 1 });
  const inOneDay = add(Date.now(), { hours: 24 });
  const inThreeDays = add(Date.now(), { days: 3 });
  const inSevenDays = add(Date.now(), { days: 7 });

  const revokeOptions = [
    {
      label: 'In 1 hour',
      value: inOneHour,
      data: {
        icon: (
          <div className={styles.dateTip}>
            {format(inOneHour, 'dd.MM.yy HH:mm zzz')}
          </div>
        ),
      },
    },
    {
      label: 'In 24 hours',
      value: inOneDay,
      data: {
        icon: (
          <div className={styles.dateTip}>
            {format(inOneDay, 'dd.MM.yy HH:mm zzz')}
          </div>
        ),
      },
    },
    {
      label: 'In 3 days',
      value: inThreeDays,
      data: {
        icon: (
          <div className={styles.dateTip}>
            {format(inThreeDays, 'dd.MM.yy HH:mm zzz')}
          </div>
        ),
      },
    },
    {
      label: 'In 7 days',
      value: inSevenDays,
      data: {
        icon: (
          <div className={styles.dateTip}>
            {format(inSevenDays, 'dd.MM.yy HH:mm zzz')}
          </div>
        ),
      },
    },
  ];
  return (
    <Modal className={styles.modal}>
      {steps === 'be_aware_message' && (
        <>
          <div className={styles.header}>
            <Text size="h3" as="h3">
              {t('apikeys:revokeBeAware.title')}
            </Text>
            <Text align="left" className={styles.textDanger}>
              {t('apikeys:revokeBeAware.text')}
            </Text>
          </div>
          <div className={styles.separator} />
          <div className={styles.actions}>
            <Button
              autoFocus
              color="secondary"
              text={t('common:cancel')}
              onClick={onClickCancel}
            />
            <Button
              color="danger"
              text={t('common:continue')}
              onClick={() => setSteps('revoke')}
            />
          </div>
        </>
      )}
      {steps === 'revoke' && (
        <>
          <div className={styles.header}>
            <Text size="h3" as="h3">
              {t('apikeys:revoke.title')}
            </Text>
            <Text color="grey">{t('apikeys:revoke.text')}</Text>
          </div>
          <div className={styles.separator} />
          <div className={styles.options}>
            <Radio
              label={t('apikeys:revoke.revokeNow')}
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value={0}
              checked={resetOption === 0}
              onChange={onChangeRevokeOption}
            />

            <Radio
              label={t('apikeys:revoke.revokeLater')}
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value={1}
              checked={resetOption === 1}
              onChange={onChangeRevokeOption}
            />

            {resetOption === 1 && (
              <div className={styles.revokeDateField}>
                <FormField
                  id="revokeDate"
                  label={t('apikeys:revoke.revokeDate')}
                >
                  <Select
                    options={revokeOptions}
                    value={revokeOptions.find(
                      (option) => option.value === dateValue
                    )}
                    onChange={(value) => setDateValue(value.value)}
                  />
                </FormField>
              </div>
            )}
          </div>

          <div className={styles.separator} />
          <div className={styles.actions}>
            <Button
              autoFocus
              color="secondary"
              text={t('common:cancel')}
              onClick={onClickCancel}
            />
            <Button
              color="danger"
              text={t('apikeys:revoke.confirm')}
              onClick={onClickRevoke}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default RevokeApiKeyModal;
