import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Button } from '@monite/ui';

import styles from './styles.module.scss';

type HelperLinkProps = {
  text?: string;
  linkUrl?: string;
  onClick?: (e: React.BaseSyntheticEvent) => void;
  linkText: string;
  secondary?: boolean;
};

const HelperLink = (props: HelperLinkProps) => {
  const { text, linkUrl, linkText, secondary, onClick } = props;

  const isExternalLink = linkUrl && /^http(s)?|mailto/.test(linkUrl);

  return (
    <div className={cn(styles.link, { [styles.secondary]: secondary })}>
      {text ? <span>{text}</span> : null}
      {linkUrl && !isExternalLink ? <Link to={linkUrl}>{linkText}</Link> : null}

      {linkUrl && isExternalLink ? <a href={linkUrl}>{linkText}</a> : null}

      {onClick ? (
        <Button
          onClick={onClick}
          text={linkText}
          noPadding
          color="blue"
          textSize="smallBold"
        />
      ) : null}

      {!linkUrl && !onClick ? (
        <span className={styles.action}>{linkText}</span>
      ) : null}
    </div>
  );
};

export default HelperLink;
