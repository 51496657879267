import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation, TFunction } from 'react-i18next';

import { ROUTES } from 'features/app/consts';
import { useSignIn } from 'features/auth/queries';
import { useAuthContext } from 'features/auth/AuthContext';

import BaseForm, { FormValues } from './Form';

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    email: Yup.string()
      .email(t('forms:invalidEmail'))
      .required(t('forms:errors.required', { field: t('forms:fields.email') })),
    password: Yup.string().required(
      t('forms:errors.required', { field: t('forms:fields.password') })
    ),
  };

  return Yup.object().shape(validators);
};

const LoginForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const signInMutation = useSignIn();
  const { login } = useAuthContext();

  const onSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    const res = await signInMutation.mutateAsync({
      email: formData.email,
      password: formData.password,
    });

    if (res?.success && res.token) {
      if (res.token === 'DeactivatedByDefault') {
        return void navigate(ROUTES.activationPending);
      } else {
        login(res.token);
        return void navigate(ROUTES.projects);
      }
    } else {
      if (res.error === 'Invalid password') {
        formHelpers.setFieldError('password', t('login:errors.emailPassword'));
        formHelpers.setFieldError('email', t('login:errors.emailPassword'));
      } else {
        formHelpers.setFieldError('password', res.error);
      }
    }
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm {...props} />;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema({ t })}
    >
      {renderForm}
    </Formik>
  );
};

export default LoginForm;
