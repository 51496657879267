import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { User } from 'features/types';
import { updateUserAvatar } from 'features/users/api';

const useUpdateUserAvatarMutation = (user: User) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FormData) => updateUserAvatar(user.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', user.id] });
      toast('User avatar was successfully changed');
    },
    onError: () => {
      let errorMessage =
        'Something went wrong. The file is too large or network connection is unstable.';
      toast(errorMessage);
    },
  });
};

export default useUpdateUserAvatarMutation;
