import { Badge, DropdownItem, TableRow } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';

import styles from './styles.module.scss';

type ApiKeyRowProps = {
  // TODO add type from openapi
  data: any;
};

//TODO get from openapi
export enum StatusEnum {
  active = 'active',
  revoked = 'revoked',
  scheduledToRevoke = 'scheduled_to_revoke',
}

const ApiKeyRow = ({ data }: ApiKeyRowProps) => {
  const { t } = useTranslation();
  const { setModal } = useModalContext();

  const onClickRevoke = () => {
    setModal({
      key: ModalKeys.REVOKE_API_KEY_MODAL,
      props: { id: data.id },
    });
  };

  const STATUS_MAP: Record<string, { color: string; label: string }> = {
    [StatusEnum.active]: { color: 'success', label: 'Active' },
    [StatusEnum.revoked]: { color: 'secondary', label: 'Revoked' },
    [StatusEnum.scheduledToRevoke]: {
      color: 'pending',
      label: `Will revoke on ${new Intl.DateTimeFormat(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(new Date(data.revoke_datetime))}`,
    },
  };

  return (
    <TableRow
      inactive={data.status === 'inactive'}
      dropdownActions={
        data.status !== StatusEnum.revoked
          ? () => (
              <DropdownItem onClick={onClickRevoke}>
                {t('apikeys:list.revoke')}
              </DropdownItem>
            )
          : null
      }
    >
      <td className={styles.keyName}>{data.client_id}</td>
      <td className={styles.keyName}>{data.client_secret_mask}</td>
      <td className={styles.status}>
        <Badge
          text={STATUS_MAP[data.status]?.label}
          color={STATUS_MAP[data.status]?.color}
        />
      </td>
      <td className={styles.date}>
        {data.created_at ? new Date(data.created_at)?.toDateString() : ''}
      </td>
      <td className={styles.date}>
        {data.last_used_at ? new Date(data.last_used_at)?.toDateString() : ''}
      </td>
    </TableRow>
  );
};

export default ApiKeyRow;
