import React, { useState } from 'react';
import { Button, Box, AvatarInput, Avatar } from '@monite/ui';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation, TFunction } from 'react-i18next';
import { useAbility } from '@casl/react';

import Sidebar from 'features/app/Modals/Sidebar';
import { validatePassword } from 'features/auth/lib';
import { useProfile, useUser, updateProfile } from 'features/users/api';
import useDisableUserMutation from 'features/users/hooks/useDisableUserMutation';
import useUpdateUserMutation from 'features/users/hooks/useUpdateUserMutation';
import useUpdateProfileAvatarMutation from 'features/users/hooks/useUpdateProfileAvatarMutation';
import useUpdateUserAvatarMutation from 'features/users/hooks/useUpdateUserAvatarMutation';
import useUpdateProfileMutation from 'features/users/hooks/useUpdateProfileMutation';
import useEnableUser from 'features/users/hooks/useEnableUser';

import {
  AbilityContext,
  RoleActions,
  RoleSubjects,
  Can,
} from 'features/roleModel';

import { ReactComponent as RightArrowIcon } from 'assets/icons/forth.svg';

import Form, { FormValues } from './Form';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import { useModalContext } from 'features/app/Modals/ModalContext';
import { useAuthContext } from 'features/auth/AuthContext';

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    name: Yup.string().required(
      t('forms:errors.required', { field: t('forms:fields.fullName') })
    ),
    email: Yup.string().required(),
    role: Yup.string(),
    oldPassword: Yup.string().min(12).test({
      test: validatePassword,
    }),
    newPassword: Yup.string().when('oldPassword', {
      is: false,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().min(12).test({
        test: validatePassword,
      }),
    }),
    repeatPassword: Yup.string().oneOf([Yup.ref('newPassword'), null]),
  };

  return Yup.object().shape(validators);
};

export type SidebarUserProps = {
  id: string;
};
const SidebarUser = ({ id }: SidebarUserProps) => {
  const { t } = useTranslation();
  const { logout } = useAuthContext();
  const { setModal } = useModalContext();
  const { data: profile } = useProfile();
  const ability = useAbility(AbilityContext);

  const isProfileEditing = profile && id === profile?.id;

  let { data: user } = useUser(isProfileEditing ? undefined : id);
  user = isProfileEditing ? profile : user;

  const disableUserMutation = useDisableUserMutation(user!);

  const onClickDisable = () => disableUserMutation.mutate(user!.id);

  const enableUserMutation = useEnableUser(user!);

  const onClickEnable = () => enableUserMutation.mutate();

  const [showPasswords, togglePasswords] = useState(false);

  const updateUserMutation = useUpdateUserMutation(user!);
  const updateProfileMutation = useUpdateProfileMutation();
  const updateProfileAvatarMutation = useUpdateProfileAvatarMutation();
  const updateUserAvatarMutation = useUpdateUserAvatarMutation(user!);
  if (!id || !user) {
    return null;
  }

  const initialValues = {
    name: user!.fullname || '',
    email: user!.email || '',
    role: user!.role_id || '',
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  };

  const onClickClose = () => {
    setModal(null);
  };

  const onClickLogout = () => {
    setModal(null);
    logout();
  };

  const onClickUserDisable = (e: React.BaseSyntheticEvent) => {
    setModal(null);
    onClickDisable();
  };

  const onChangeAvatar = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (
      e.currentTarget.validity.valid &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const data = new FormData();
      data.append('file', e.currentTarget.files[0]);

      if (isProfileEditing) {
        updateProfileAvatarMutation.mutate(data);
      } else {
        updateUserAvatarMutation.mutate(data);
      }
    }
  };

  const canUpdateUser =
    ability.can(RoleActions.UPDATE, RoleSubjects.USER) ||
    ability.can(RoleActions.UPDATE, RoleSubjects.USER_OWN);

  const onFormSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    if (isProfileEditing) {
      if (initialValues.name !== formData.name) {
        updateProfileMutation.mutate({
          fullname: formData.name,
        });
        return;
      }

      const res = await updateProfile({
        password: {
          old_password: formData?.oldPassword,
          new_password: formData?.newPassword,
        },
      });

      if (!res) {
        return;
      }

      if (!res.success) {
        if (res.error === t('forms:invalidOldPasswords')) {
          formHelpers.setFieldError('oldPassword', res.error);
          return;
        }
        if (res.error === t('forms:invalidNewPasswordsMatchOldPasswords')) {
          formHelpers.setFieldError('newPassword', res.error);
          return;
        }
        return;
      } else {
        togglePasswords(false);
        toast(t('users:form.profileChanged'));
      }
    } else {
      updateUserMutation.mutate({
        fullname: formData.name,
        ...(initialValues.role !== formData.role
          ? { role_id: formData.role }
          : {}),
      });
    }
  };

  const renderFormWrapper = (props: FormikProps<FormValues>) => {
    const { submitForm, isValid, values, dirty } = props;

    let isUserPasswordChange = true;
    let isUserNameChange = true;

    if (
      initialValues.name !== values.name &&
      !values.oldPassword &&
      !values.newPassword &&
      !values.repeatPassword
    ) {
      isUserNameChange = false;
    } else isUserNameChange = true;

    if (
      initialValues.name === values.name &&
      values.oldPassword &&
      values.newPassword &&
      values.repeatPassword &&
      isValid
    ) {
      isUserPasswordChange = false;
    } else isUserPasswordChange = true;

    return (
      <Sidebar>
        <div className={styles.breadcrumbs}>
          <span>{t('users:form.path')}</span>
          <i>
            <RightArrowIcon width={20} height={20} />
          </i>
          <span className={styles.active}>{user!.fullname || user!.email}</span>
        </div>
        <div className={styles.content}>
          <div className={styles.avatarInput}>
            {ability.can(RoleActions.UPDATE, RoleSubjects.USER) ||
            isProfileEditing ? (
              <AvatarInput
                isLoading={updateProfileAvatarMutation.isPending}
                url={user!.avatar?.url}
                name={user!.fullname || user!.email}
                onChange={onChangeAvatar}
              />
            ) : (
              <Avatar
                url={user!.avatar?.url}
                size={80}
                name={user!.fullname || user!.email}
                onlyLetter
              />
            )}
          </div>
          <Form
            profile={profile!}
            onClickClose={onClickClose}
            isProfileEditing={isProfileEditing}
            showPasswords={showPasswords}
            togglePasswords={togglePasswords}
            {...props}
          />
        </div>
        <div className={styles.buttons}>
          {isProfileEditing ? (
            <Button
              color="grey"
              hover="black"
              text={t('users:form.logout')}
              onClick={onClickLogout}
            />
          ) : (
            <Can I={RoleActions.UPDATE} a={RoleSubjects.USER}>
              <Button
                color="grey"
                hover="red"
                text={
                  user?.status === 'deleted'
                    ? t('common:reactivate')
                    : t('common:disable')
                }
                onClick={
                  user?.status === 'deleted'
                    ? onClickEnable
                    : onClickUserDisable
                }
              />
            </Can>
          )}
          <Box mx="auto" />
          <Button
            color="grey"
            hover="black"
            text={t('common:close')}
            onClick={onClickClose}
          />
          {canUpdateUser && (
            <Button
              text={t('forms:saveChanges')}
              onClick={submitForm}
              disabled={
                isProfileEditing
                  ? isUserPasswordChange && isUserNameChange
                  : !dirty
              }
            />
          )}
        </div>
      </Sidebar>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema({ t })}
    >
      {renderFormWrapper}
    </Formik>
  );
};

export default SidebarUser;
