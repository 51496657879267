import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'features/api';
import type {
  Project,
  ProjectSettings,
  Region,
  Feature,
  Currency,
  Environment,
  ProjectsRequest,
  ProjectUpdateRequest,
} from 'features/types';

export const getProjects = async () => {
  const res = await apiClient.get<Project[]>('projects');
  return res.data || [];
};

export const getProjectsById = async (id: string) => {
  const res = await apiClient.get<Project>(`projects/${id}`);
  return res.data || null;
};

export const createProject = async (data: ProjectsRequest) => {
  const res = await apiClient.post<Project>('projects', data);
  return res.data || null;
};

export const updateProjectById = async (
  id: string,
  data: ProjectUpdateRequest
) => {
  const res = await apiClient.patch<ProjectUpdateRequest>(
    `projects/${id}`,
    data
  );

  return res.data || null;
};

export const getProjectSettingsById = async (id: string) => {
  const res = await apiClient.get<ProjectSettings>(`projects/${id}/settings`);
  return res.data || null;
};

export const useProjects = () => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => getProjects(),
  });
};

export const useProjectQuery = (id?: string) => {
  return useQuery({
    queryKey: ['project', id],
    queryFn: () => (id ? getProjectsById(id) : null),
    enabled: !!id,
  });
};

export const useProjectSettingsQuery = (id?: string) => {
  return useQuery({
    queryKey: ['project_settings', id],
    queryFn: () => (id ? getProjectSettingsById(id) : null),
    enabled: !!id,
  });
};

// dicts

export const getRegions = async () => {
  const res = await apiClient.get<Region[]>('dicts/regions');
  return res.data || [];
};

export const useRegions = () => {
  return useQuery({
    queryKey: ['regions'],
    queryFn: () => getRegions(),
  });
};

export const getEnvironments = async () => {
  const res = await apiClient.get<Environment[]>('dicts/environments');
  return res.data || [];
};

export const useEnvironments = () => {
  return useQuery({
    queryKey: ['environments'],
    queryFn: () => getEnvironments(),
  });
};

export const getCurrencies = async () => {
  const res = await apiClient.get<Currency[]>('dicts/currencies');
  return res.data || [];
};

export const useCurrencies = () => {
  return useQuery({
    queryKey: ['currencies'],
    queryFn: () => getCurrencies(),
  });
};

export const getFeatures = async () => {
  const res = await apiClient.get<Feature[]>('dicts/features');
  return res.data || [];
};

export const useFeatures = () => {
  return useQuery({
    queryKey: ['features'],
    queryFn: () => getFeatures(),
  });
};
