import React from 'react';
import { Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import { TokenData } from 'features/types';

type PageJoinSentProps = {
  tokenData: TokenData;
};
const PageJoinSent = ({ tokenData }: PageJoinSentProps) => {
  const { t } = useTranslation();

  return (
    <AuthLayout withContactsLink title={t('join:title')}>
      <h2>{t('join2:header')}</h2>
      <Text>
        {t('join2:text', {
          companyText:
            tokenData && tokenData.company
              ? `${t('join2:companyText', { company: tokenData.company })} `
              : '',
        })}
      </Text>
    </AuthLayout>
  );
};

export default PageJoinSent;
