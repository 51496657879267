import { createGlobalStyle } from 'styled-components';
import { upperFirst } from 'lodash';

const getThemeStr = (props: any) => {
  const { theme } = props;

  let themeStr: string = '';
  Object.keys(theme).forEach((sectionKey) => {
    Object.keys(theme[sectionKey]).forEach((styleKey) => {
      themeStr += `
      --${sectionKey}${upperFirst(styleKey)}: ${
        props.theme[sectionKey][styleKey]
      };
      `;
    });
  });

  return themeStr;
};

export default createGlobalStyle<any>`
  :root {
      ${(props) => getThemeStr(props)}
  }
`;
