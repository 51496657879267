import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'features/app/consts';

type SeoProps = {
  description?: string;
  title?: string;
  keywords?: string;
};

export default function Seo({
  description: propDescription,
  title: propTitle,
  keywords: propKeywords,
}: SeoProps) {
  const location = useLocation();
  const { pathname } = location;
  const isAuthPath = [ROUTES.signin, ROUTES.signup].includes(pathname);

  const title = propTitle
    ? `${propTitle} - Monite`
    : 'Monite - Add Finance Automation to your product';
  const description =
    propDescription ||
    'Monite lets B2B platforms add Invoicing, Payables, and other finance automation features to their offering | Fully embeddable | Cross-country coverage | High-end functionality';

  return (
    <Helmet>
      {process.env.NODE_ENV === 'production' && isAuthPath && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-LBZF657FK6`}
        ></script>
      )}
      {process.env.NODE_ENV === 'production' && isAuthPath && (
        <script src="/gtag.js"></script>
      )}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={propKeywords || 'monite,partner,portal,dashboard'}
      />
    </Helmet>
  );
}
