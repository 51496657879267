/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PortalPermissionEnum {
    ALLOWED = 'allowed',
    ALLOWED_FOR_OWN = 'allowed_for_own',
    NOT_ALLOWED = 'not_allowed',
}