import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateUserById } from 'features/users/api';
import { User, UserUpdatePayload } from 'features/types';
import { capitalize } from 'lodash';
import { ROLES } from '../consts';

const useUpdateProfileMutation = (user: User) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: UserUpdatePayload) => updateUserById(user.id, data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['user', user.id] });
      const previousData = queryClient.getQueryData<User>(['user', user.id]);

      queryClient.setQueryData(['user', user.id], () => ({
        ...(previousData || {}),
        fullname: data.fullname,
      }));

      return { previousData };
    },
    onError: (err, data, context: any) => {
      queryClient.setQueryData(['user', user.id], context.previousData);
    },
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: ['user', user.id] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      if (data.role_id !== user.role_id) {
        toast(
          `${t('users:form.roleChanged', {
            name: user.fullname || user.email,
          })} ${capitalize(data.role_id ? ROLES.admin : ROLES.user)}`
        );
      } else if (!vars.password) {
        toast(t('users:form.userChanged'));
      }

      if (vars.password) {
        toast(
          t('users:form.passwordChanged', {
            name: user.fullname || user.email,
          })
        );
      }
    },
  });
};

export default useUpdateProfileMutation;
