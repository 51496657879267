import { Select, SelectProps } from '@monite/ui';
import { useRoles } from 'features/users/api';
import { QuestionIcon, Button } from '@monite/ui';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'features/users/consts';

type UserRolesSelectProps = {
  value?: any;
} & Omit<SelectProps, 'options' | 'value'>;

enum Roles {
  admin = 'admin',
  business = 'business',
  developer = 'developer',
}
const UserRolesSelect = (props: UserRolesSelectProps) => {
  const { data: roles } = useRoles();

  const { t } = useTranslation();

  const roleTooltipTexts = {
    admin: t('users:invite.adminTip'),
    developer: t('users:invite.developerTip'),
    business: t('users:invite.businessTip'),
  };

  const options = (roles || [])
    .filter((role) => role.name !== ROLES.owner || props.isDisabled)
    .map((role) => ({
      label: capitalize(role.name),
      value: role.id,
      data: {
        icon: (
          <Button
            type="button"
            noPadding
            color="link"
            icon={<QuestionIcon />}
            tooltip={{
              tip: roleTooltipTexts[role.name as Roles],
            }}
            style={{ minWidth: 0, marginTop: 0 }}
          />
        ),
      },
    }));

  return (
    <Select
      {...props}
      options={options}
      value={options.find((o) => o.value === props.value)}
    />
  );
};

export default UserRolesSelect;
