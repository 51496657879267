import React from 'react';
import { useLocation } from 'react-router-dom';

import PublicArea from 'features/app/routes/PublicArea';
import AuthorizedArea from 'features/app/routes/AuthorizedArea';

import GlobalTooltip from './GlobalTooltip';
import GlobalToast from './GlobalToast';
import GlobalTheme from './GlobalTheme';

import DevTools from 'features/dev/DevTools';
import { useAuthContext } from '../auth/AuthContext';

function App() {
  const { isAuth } = useAuthContext();
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <DevTools />
      <GlobalTheme />
      <GlobalTooltip />
      <GlobalToast />
      {isAuth ? <AuthorizedArea /> : <PublicArea />}
    </>
  );
}

export default App;
