import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Company } from 'features/types';

import { updateCompanyLogo } from 'features/companies/api';

const useUpdateCompanyLogoMutation = (company: Company) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: FormData) => updateCompanyLogo(company.id, data),
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ['company', company.id] });
      queryClient.invalidateQueries({ queryKey: ['profile'] });

      toast(t('companies:form.companyChanged'));
    },
    onError: () => {
      let errorMessage = t('forms:fileError');
      toast(errorMessage);
    },
  });
};

export default useUpdateCompanyLogoMutation;
