import { FormikProps } from 'formik';
import { FormField, Input, AvatarInput, Button } from '@monite/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from 'features/types';
import ReactTooltip from 'react-tooltip';
import { useAbility } from '@casl/react';

import useUpdateCompanyLogoMutation from 'features/companies/hooks/useUpdateCompanyLogoMutation';
import {
  Can,
  RoleActions,
  RoleSubjects,
  AbilityContext,
} from 'features/roleModel';
import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';

export type FormValues = {
  name: string;
  website: string;
  email: string;
  phone: string;
};

interface BaseFormProps extends FormikProps<FormValues> {
  company: Company;
}

type BaseTextFieldProps = {
  id: keyof FormValues;
  label: string;
  readOnly?: boolean;
};
const BaseTextField = (props: BaseTextFieldProps & BaseFormProps) => {
  const {
    id,
    label,
    errors,
    values,
    handleBlur,
    handleChange,
    touched,
    readOnly,
  } = props;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <FormField
      id={id}
      label={label}
      error={errors[id] && touched[id] ? errors[id] : ''}
    >
      <Input
        id={id}
        isInvalid={Boolean(errors[id] && touched[id])}
        value={values[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly}
      />
    </FormField>
  );
};

const BaseForm = (props: BaseFormProps) => {
  const { company, handleSubmit, dirty, isValid, isSubmitting } = props;

  const { t } = useTranslation();
  const { setModal } = useModalContext();
  const ability = useAbility(AbilityContext);
  const isReadOnly = !ability.can(RoleActions.UPDATE, RoleSubjects.COMPANY_OWN);

  const updateCompanyLogoMutation = useUpdateCompanyLogoMutation(company);

  const onChangeLogo = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (
      e.currentTarget.validity.valid &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const data = new FormData();
      data.append('file', e.currentTarget.files[0]);

      updateCompanyLogoMutation.mutate(data);
    }
  };

  const onClickDisable = () => {
    setModal({ key: ModalKeys.DISABLE_PROFILE_MODAL });
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <AvatarInput
        name={company.name}
        isLoading={updateCompanyLogoMutation.isPending}
        url={company!.logo?.url}
        onChange={onChangeLogo}
      />
      <BaseTextField
        id="name"
        label={t('companies:name')}
        {...props}
        readOnly={isReadOnly}
      />
      <BaseTextField
        id="website"
        label={t('companies:website')}
        {...props}
        readOnly={isReadOnly}
      />
      <BaseTextField
        id="email"
        label={t('companies:email')}
        {...props}
        readOnly={isReadOnly}
      />
      <BaseTextField
        id="phone"
        label={t('companies:phone')}
        {...props}
        readOnly={isReadOnly}
      />

      <Can I={RoleActions.DELETE} a={RoleSubjects.COMPANY_OWN}>
        <Button
          text={t('users:form.delete')}
          color="danger"
          onClick={onClickDisable}
          tooltip={{ tip: t('users:disable.disableTip') }}
        />
      </Can>
      <Can I={RoleActions.UPDATE} a={RoleSubjects.COMPANY_OWN}>
        <Button
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
          text={t('companies:submit')}
        />
      </Can>
    </form>
  );
};

export default BaseForm;
