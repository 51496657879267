import React from 'react';
import { Badge, DropdownItem, TableRow, Avatar } from '@monite/ui';
import { useTranslation } from 'react-i18next';
import { useAbility } from '@casl/react';
import { capitalize } from 'lodash';

import type { User } from 'features/types';
import useDisableUserMutation from 'features/users/hooks/useDisableUserMutation';
import useEnableUser from 'features/users/hooks/useEnableUser';
import { useRoles, useProfile } from 'features/users/api';
import { AbilityContext, RoleActions, RoleSubjects } from 'features/roleModel';
import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';

type UserRowProps = {
  data: User;
};

const STATUS_NAMES_MAP: Record<string, string> = {
  active: 'active',
  deleted: 'disabled',
  sent_confirmation: 'pending',
};

const STATUS_COLOR_MAP: Record<string, string> = {
  active: 'success',
  inactive: 'warning',
  deleted: 'warning',
  sent_confirmation: 'pending',
  pending: 'pending',
};

const UserRow = ({ data: user }: UserRowProps) => {
  const { setModal } = useModalContext();
  const { t } = useTranslation();
  const { data: roles } = useRoles();
  const ability = useAbility(AbilityContext);

  const disableUserMutation = useDisableUserMutation(user!);

  const onClickDisable = () => disableUserMutation.mutate(user!.id);

  const enableUserMutation = useEnableUser(user!);

  const onClickEnable = () => enableUserMutation.mutate();

  const isDisabled = user.status === 'deleted';

  const { data: profile } = useProfile();
  const isProfileEditing = profile && user && user.id === profile?.id;

  const userRoleName = capitalize(
    roles?.find((role) => user.role_id === role.id)?.name
  );

  const onClickRow = () => {
    setModal({
      key: ModalKeys.USER_SIDEBAR,
      props: {
        id: user.id,
      },
    });
  };

  return (
    <TableRow
      onClick={onClickRow}
      inactive={isDisabled}
      dropdownActions={
        ability.can(RoleActions.UPDATE, RoleSubjects.USER) ||
        (ability.can(RoleActions.UPDATE, RoleSubjects.USER_OWN) &&
          isProfileEditing)
          ? () => (
              <>
                <DropdownItem onClick={onClickRow}>
                  {t('common:edit')}
                </DropdownItem>
                {!isProfileEditing && (
                  <DropdownItem
                    onClick={
                      user.status === 'deleted' ? onClickEnable : onClickDisable
                    }
                  >
                    {user.status === 'deleted'
                      ? t('common:reactivate')
                      : t('common:disable')}
                  </DropdownItem>
                )}
              </>
            )
          : null
      }
    >
      <td>
        <Avatar
          url={user?.avatar?.url}
          name={user.fullname}
          inactive={isDisabled}
        />
      </td>
      <td>{userRoleName}</td>
      <td>{user.email}</td>
      <td>
        <Badge
          text={t(
            `users:statuses.${
              STATUS_NAMES_MAP[(user.status || '').toLowerCase()]
            }`,
            user.status
          )}
          color={STATUS_COLOR_MAP[(user.status || '').toLowerCase()]}
        />
      </td>
    </TableRow>
  );
};

export default UserRow;
