import RevokeApiKeyModal from 'features/apikeys/RevokeApiKey';
import ModalInvite from 'features/users/ModalInvite';
import ModalCreateApiKey from 'features/apikeys/ModalCreateApiKey';
import ModalDisableProfile from 'features/users/ModalDisableProfile';
import SidebarUser, { SidebarUserProps } from 'features/users/SidebarUser';
import { useModalContext } from './ModalContext';

export enum ModalKeys {
  REVOKE_API_KEY_MODAL = 'REVOKE_API_KEY_MODAL',
  INVITE_MODAL = 'INVITE_MODAL',
  CREATE_API_KEY_MODAL = 'CREATE_API_KEY_MODAL',
  DISABLE_PROFILE_MODAL = 'DISABLE_PROFILE_MODAL',
  USER_SIDEBAR = 'SIDEBAR_USER',
}

export type ModalProps = { id: string };

const GlobalModal = () => {
  const { modal } = useModalContext();
  const { key: modalKey, props: modalProps } = modal || {};

  if (!modalKey) {
    return null;
  }

  if (modalKey === ModalKeys.REVOKE_API_KEY_MODAL) {
    return <RevokeApiKeyModal {...(modalProps as { id: string })} />;
  }

  if (modalKey === ModalKeys.INVITE_MODAL) {
    return <ModalInvite {...(modalProps as {})} />;
  }

  if (modalKey === ModalKeys.CREATE_API_KEY_MODAL) {
    return <ModalCreateApiKey {...(modalProps as { id: string })} />;
  }

  if (modalKey === ModalKeys.DISABLE_PROFILE_MODAL) {
    return <ModalDisableProfile {...(modalProps as {})} />;
  }

  if (modalKey === ModalKeys.USER_SIDEBAR) {
    return <SidebarUser {...((modalProps || {}) as SidebarUserProps)} />;
  }

  return null;
};

export default GlobalModal;
