import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'features/api';
import { GetSecretsResponse } from 'features/types';

export const getApiKeys = async (id: string) => {
  const res = await apiClient.get<GetSecretsResponse>(
    `secrets?project_id=${id}`
  );
  return res.data || [];
};

export const createApiKey = async (data: any) => {
  const res = await apiClient.post<any>('secrets', data);
  return res.data || null;
};

export const revokeApiKey = async (id: string, delay?: number) => {
  const res = await apiClient.delete(
    `secrets/${id}${delay ? `?delay_in_seconds=${delay}` : ''}`
  );
  return res.data;
};

export const useApiKeys = (projectId: string) => {
  return useQuery({
    queryKey: ['apiKeys', projectId],
    queryFn: () => getApiKeys(projectId),
    enabled: !!projectId,
  });
};
