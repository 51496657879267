import { FormikProps } from 'formik';
import { Button, FormField, Input } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import HelperLink from 'features/auth/Layout/HelperLink';
import { ROUTES } from 'features/app/consts';

export type FormValues = {
  email: string;
};

interface BaseFormProps extends FormikProps<FormValues> {}

const BaseForm = (props: BaseFormProps) => {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    dirty,
    isValid,
    handleBlur,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <form method="post" onSubmit={handleSubmit}>
        <FormField
          id="email"
          label={t('forms:fields.email')}
          error={errors.email && touched.email ? errors.email : ''}
        >
          <Input
            id="email"
            required
            isInvalid={Boolean(errors.email && touched.email)}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        {isSubmitting ? (
          <Button type="submit" disabled isLoading />
        ) : (
          <Button
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
            text={t('forgot:submit')}
          />
        )}

        <HelperLink linkUrl={ROUTES.signin} linkText={t('forgot:back')} />
      </form>
    </div>
  );
};

export default BaseForm;
