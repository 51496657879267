import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ROUTES } from 'features/app/consts';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const DevButtton = styled.div`
  z-index: 1001;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background: red;
  opacity: 0.5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const DevBar = styled.div<{ shown: boolean }>`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  max-width: 300px;
  background: #112233;
  border-left: black solid 2px;

  display: flex;
  flex-direction: column;
  color: white;
  padding: 50px 20px 20px 20px;

  transform: translateX(100%);
  transition: transform 200ms;

  ${({ shown }) => (shown ? 'transform: translateX(0);' : '')}
`;

const CloseButton = styled.button`
  border: white solid 0px;
  background: none;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 4px;
  color: white;
  cursor: pointer;
`;

const Routes = styled.nav`
  > * + * {
    margin-top: 8px;
  }

  a {
    color: white;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const DevTools = () => {
  const [shownDevBar, toggleDevBar] = useState(false);

  return (
    <>
      <DevButtton
        onClick={() => toggleDevBar((shownDevBar) => !shownDevBar)}
        title="DevTools"
      />
      <DevBar shown={shownDevBar}>
        <CloseButton onClick={() => toggleDevBar(false)}>
          <CloseIcon width={36} height={36} />
        </CloseButton>
        <Content>
          <Routes>
            <Link to={ROUTES.projects}>Projects</Link>
            <Link to={ROUTES.apiKeysDemo}>API Keys</Link>

            <Link to={ROUTES.signin}>Sign In</Link>
            <Link to={ROUTES.signup}>Sign Up</Link>

            <Link to={ROUTES.reset}>Reset</Link>
            <Link to={ROUTES.resetCheck}>Reset Check</Link>
            <Link to={ROUTES.resetPassword}>Reset Password</Link>
            <Link to={ROUTES.resetTokenDemo}>Reset Verify</Link>

            <Link to={ROUTES.confirm}>Email Confirm</Link>
            <Link to={ROUTES.confirmTokenDemo}>Email Confirm Verify</Link>
          </Routes>
        </Content>
      </DevBar>
    </>
  );
};

const DevToolsWrapper = () => {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return <DevTools />;
};

export default DevToolsWrapper;
