import { createContext, useContext, useState, ReactNode } from 'react';
import { ModalKeys, ModalProps } from './index';

export type Modal = {
  key: ModalKeys;
  props?: ModalProps;
} | null;

interface ModalContextProps {
  modal?: Modal;
  setModal: (modal: Modal) => void;
}

interface ModalContextProviderProps {
  initialModal?: Modal | null;
  children: ReactNode;
}

export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined
);
ModalContext.displayName = 'ModalContext';

export const ModalContextProvider = ({
  initialModal,
  children,
}: ModalContextProviderProps) => {
  const [modal, setModal] = useState<Modal>(initialModal || null);

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider'
    );
  }

  return context;
};
