import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation, TFunction } from 'react-i18next';

import { ROUTES } from 'features/app/consts';
import { validatePassword } from 'features/auth/lib';

import BaseForm, { FormValues } from './Form';
import { useChangePassword } from 'features/auth/queries';
import { useAuthContext } from '../../AuthContext';

type FormProps = {
  token: string;
};

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    password: Yup.string()
      .min(12)
      .test({
        test: validatePassword,
      })
      .required(),
    repeatPassword: Yup.string()
      .required(t('forms:invalidPasswords'))
      .oneOf([Yup.ref('password')], t('forms:invalidPasswords')),
  };

  return Yup.object().shape(validators);
};

const Form = ({ token }: FormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setResetPasswordData } = useAuthContext();
  const changePasswordMutation = useChangePassword();

  const onSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    const res = await changePasswordMutation.mutateAsync({
      new_password: formData.password,
      token,
    });

    if (res?.success) {
      toast(t('forgot4:success'));
      navigate(ROUTES.signin);
      setResetPasswordData(null);
    } else {
      formHelpers.setFieldError(
        'repeatPassword',
        res?.error || t('common:somethingWrong')
      );
    }
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm {...props} />;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ password: '', repeatPassword: '' }}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema({ t })}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;
