import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { disableUserById } from 'features/users/api';
import { deleteCompanyById } from 'features/companies/api';

import { User } from 'features/types';
import { ROUTES } from 'features/app/consts';
import { useModalContext } from 'features/app/Modals/ModalContext';
import { useAuthContext } from 'features/auth/AuthContext';

const useDisableUserMutation = (user: User) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const { setModal } = useModalContext();
  const { logout } = useAuthContext();
  const navigate = useNavigate();

  const profile = client.getQueryData<User>(['profile']);
  const isProfileEditing = profile && user && user.id === profile?.id;
  //TODO refactor and fix
  return useMutation({
    mutationFn: (id: string) =>
      isProfileEditing ? deleteCompanyById(id) : disableUserById(id),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['users'] });
      if (data) {
        if (isProfileEditing) {
          setModal(null);
          logout();
          navigate(ROUTES.deletedAccount);
        } else {
          toast(
            t('users:disable.userDisabled', {
              name: user!.fullname || user!.email,
            })
          );
        }
      }
    },
    onError: () => {
      toast.error(t('common:somethingWrong'));
    },
  });
};

export default useDisableUserMutation;
