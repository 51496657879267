import { useEffect, useState } from 'react';

const useCountdown = (initialState: number = 0) => {
  const [timeLeft, setTimeLeft] = useState(initialState);

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    const timerId = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft]);

  return { timeLeft, setTimeLeft };
};

export default useCountdown;
