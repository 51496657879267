import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability, AbilityBuilder, AnyAbility } from '@casl/ability';

import {
  RolesResponse,
  PortalActionEnum,
  PortalPermissionEnum,
} from 'features/types';

export const AbilityContext = createContext<AnyAbility>({} as AnyAbility);

export const Can = createContextualCan(AbilityContext.Consumer);

export enum RoleActions {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum RoleSubjects {
  PROJECT = 'project',
  COMPANY = 'company',
  SECRET = 'secret',
  USER = 'user',
  ALL = 'all',
  PROJECT_OWN = 'project_own',
  COMPANY_OWN = 'company_own',
  SECRET_OWN = 'secret_own',
  USER_OWN = 'user_own',
}

type AppAbility = Ability<[any, RoleSubjects]>;

export const defineAbilityFor = (role?: RolesResponse) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(Ability);

  role?.permissions?.objects?.forEach(({ actions, object_type }) => {
    if (actions && object_type) {
      //eslint-disable-next-line
      actions.forEach((action) => {
        if (action.permission === PortalPermissionEnum.ALLOWED)
          can(action.action_name as PortalActionEnum, object_type as any);
        else if (action.permission === PortalPermissionEnum.ALLOWED_FOR_OWN) {
          can(
            action.action_name as PortalActionEnum,
            `${object_type}_own` as RoleSubjects
          );
        }
      });
    }
  });

  can(PortalActionEnum.READ, RoleSubjects.ALL);

  return new Ability(rules);
};
