import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useRef } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

import { validatePassword } from 'features/auth/lib';
import { ROUTES } from 'features/app/consts';

import BaseForm, { FormValues } from './Form';
import { useProfile } from 'features/users/api';
import { TokenData } from 'features/types';
import { toast } from 'react-toastify';
import { useAcceptInvitation, useSignUp } from 'features/auth/queries';
import { useAuthContext } from 'features/auth/AuthContext';

const commonValidationSchema = ({ t }: { t: TFunction }) => ({
  fullName: Yup.string().required(
    t('forms:errors.required', { field: t('forms:fields.fullName') })
  ),
  password: Yup.string()
    .min(12)
    .test({
      test: validatePassword,
    })
    .required(),
  repeatPassword: Yup.string()
    .required(t('forms:invalidPasswords'))
    .oneOf([Yup.ref('password')], t('forms:invalidPasswords')),
});

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    ...commonValidationSchema({ t }),
    email: Yup.string()
      .email(t('forms:invalidEmail'))
      .required(t('forms:invalidEmail')),
    companyName: Yup.string().required(
      t('forms:errors.required', { field: t('forms:fields.companyName') })
    ),
    companyWebsite: Yup.string().required(
      t('forms:errors.required', { field: t('forms:fields.companyWebsite') })
    ),
  };

  return Yup.object().shape(validators);
};

const getValidationSchemaForInvitation = ({ t }: { t: TFunction }) => {
  const validators = {
    ...commonValidationSchema({ t }),
  };

  return Yup.object().shape(validators);
};

type SignupFormProps = {
  token?: string;
  tokenData?: TokenData;
};
const SignupForm = ({ token, tokenData }: SignupFormProps) => {
  const navigate = useNavigate();
  const { setConfirmationEmail } = useAuthContext();
  const [emailUsedError, setEmailUsedError] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { data: profile } = useProfile();
  const { t } = useTranslation();
  const acceptInvitationMutation = useAcceptInvitation();
  const signUpMutation = useSignUp();
  const { login } = useAuthContext();
  const onSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    setEmailUsedError(false);

    if (token) {
      const res = await acceptInvitationMutation.mutateAsync({
        fullname: formData.fullName,
        password: formData.password,
        invitation_token: token,
      });

      if (!res.success) {
        if (res.error === t('forms:invalidUserAccount')) {
          toast(t('forms:invalidUserAccount'));
        }
        toast(res.error);
      }

      if (res.token) {
        if (res.token === 'DeactivatedByDefault') {
          return void navigate(ROUTES.activationPending);
        } else {
          return void login(res.token);
        }
      }

      return void toast(t('common:somethingWrong'));
    }

    const res = await signUpMutation.mutateAsync({
      email: formData.email,
      fullname: formData.fullName,
      company_name: formData.companyName,
      password: formData.password,
      company_website: formData.companyWebsite,
    });

    if (!res) {
      return void toast(t('common:somethingWrong'));
    }

    if (!res.success) {
      // TODO: replace error message with error code matching in case of error codes presence
      if (res.error === 'The user already exists.') {
        setEmailUsedError(true);
        emailInputRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
      if (res.invalidWebsite) {
        formHelpers.setFieldError(
          'companyWebsite',
          t('signup:errors.invalidWebsite')
        );
      }
      toast(res.error);
      return;
    }

    setConfirmationEmail(formData.email);

    navigate(ROUTES.confirm);
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return (
      <BaseForm
        emailUsedError={emailUsedError}
        companyName={tokenData?.company_name}
        emailInputRef={emailInputRef}
        token={token}
        {...props}
      />
    );
  };

  const initialValues = {
    companyName: profile?.company.name || '',
    email: '',
    fullName: '',
    password: '',
    repeatPassword: '',
    companyWebsite: '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        token
          ? getValidationSchemaForInvitation({ t })
          : getValidationSchema({ t })
      }
    >
      {renderForm}
    </Formik>
  );
};

export default SignupForm;
