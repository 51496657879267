import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useLockBodyScroll } from 'react-use';

import { Modal, ModalDialog, ModalProps, ModalDialogProps } from '@monite/ui';
import { useModalContext } from './ModalContext';

export const MODAL_VARIANTS: Record<ModalVariants, ModalVariants> = {
  dialog: 'dialog',
};

type ModalVariants = 'dialog';
type ModalVariantProps = ModalDialogProps | ModalProps;
type GlobalModalProps = {
  children: React.ReactNode;
  variant?: ModalVariants;
} & ModalVariantProps;
const GlobalModalWrapper = ({
  children,
  variant,
  ...props
}: GlobalModalProps) => {
  const { modal, setModal } = useModalContext();
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useLockBodyScroll(!!modal);

  useEffect(() => {
    if (modal) {
      document.body.classList.add('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [modal]);

  if (!modal) {
    return null;
  }

  const onClickBackdrop = () => {
    setModal(null);
  };

  if (variant === MODAL_VARIANTS.dialog) {
    return (
      <ModalDialog onCancel={onClickBackdrop} {...(props as ModalDialogProps)}>
        {children}
      </ModalDialog>
    );
  }

  return (
    <Modal onClickBackdrop={onClickBackdrop} {...props}>
      {children}
    </Modal>
  );
};

export default GlobalModalWrapper;
