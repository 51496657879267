import { useState } from 'react';
import { FormField, Input, Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { User } from 'features/types';
import useDisableUserMutation from 'features/users/hooks/useDisableUserMutation';
import Modal, { MODAL_VARIANTS } from 'features/app/Modals/Modal';

import styles from './styles.module.scss';

const CORRECT_WORD = 'elimination';

const ModalDisableProfile = () => {
  const { t } = useTranslation();

  const client = useQueryClient();

  const profile = client.getQueryData<User>(['profile']);

  const disableUserMutation = useDisableUserMutation(profile!);

  const [verificationWord, setVerificationWord] = useState('');
  const [verificationError, setVerificationError] = useState('');

  const onConfirmDisableUser = () =>
    disableUserMutation.mutate(profile!.company.id);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    setVerificationError('');
    setVerificationWord(e.target.value);
  };

  const handleBlur = () => {
    if (verificationWord !== CORRECT_WORD) {
      setVerificationError(t('users:disable.verificationError'));
    }
  };

  return (
    <Modal
      variant={MODAL_VARIANTS.dialog}
      title={t('users:disable.title')}
      confirmButtonTitle={t('users:disable.title')}
      size="md"
      titleProps={{ size: 'h2' }}
      cancelButtonTitle=""
      onConfirm={onConfirmDisableUser}
      confirmButtonProps={{
        block: true,
        color: 'danger',
        disabled: verificationWord !== CORRECT_WORD,
      }}
      footerSeparator={false}
      className={styles.modal}
    >
      <div className={styles.wrapper}>
        <Text align="center">
          {t('users:disable.textProfile', { company: profile?.company?.name })}
        </Text>
        <div className={styles.form}>
          <FormField
            id="verificationWord"
            label={t('users:disable.verificationWord')}
            error={verificationError}
          >
            <Input
              id="verificationWord"
              required
              value={verificationWord}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDisableProfile;
