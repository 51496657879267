import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { User } from 'features/types';
import { enableUserById } from 'features/users/api';

const useEnableUser = (user: User) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: () => enableUserById(user.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user', user.id] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      toast(
        `${t('users:form.reactivated', {
          name: user.fullname || user.email,
        })}`
      );
    },
    onError: () => {
      toast.error(t('common:somethingWrong'));
    },
  });
};

export default useEnableUser;
