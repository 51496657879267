import { FormikProps } from 'formik';
import { FormField, Input, Button } from '@monite/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CurrencySelect from 'features/projects/CurrencySelect';

import type { CurrencyEnum } from 'features/types';

export type FormValues = {
  name: string;
  currency: CurrencyEnum | '';
};

interface BaseFormProps extends FormikProps<FormValues> {}

const BaseForm = (props: BaseFormProps) => {
  const {
    handleSubmit,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    isValid,
    isSubmitting,
  } = props;

  const { t } = useTranslation();

  return (
    <form method="post" onSubmit={handleSubmit}>
      <FormField
        id="name"
        label={t('projects:new.name')}
        error={errors.name && touched.name ? errors.name : ''}
      >
        <Input
          id="name"
          required
          isInvalid={Boolean(errors.name && touched.name)}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>

      <FormField
        id="currency"
        label={t('projects:settings.currency')}
        error={errors.currency && touched.currency ? errors.currency : ''}
      >
        <CurrencySelect
          id="currency"
          name="currency"
          onChange={(v) => {
            setFieldValue('currency', v.value);
          }}
          onBlur={handleBlur}
          value={values.currency}
        />
      </FormField>

      <Button
        type="submit"
        text={t('projects:settings.submit')}
        disabled={!isValid || isSubmitting}
      />
    </form>
  );
};

export default BaseForm;
