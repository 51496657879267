import React, { useState } from 'react';
import { Text, Button } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import useCountdown from 'features/auth/hooks/useCountdown';
import { useAuthContext } from '../AuthContext';
import { useResendConfirmationEmail } from '../queries';

import styles from './styles.module.scss';

const PageSignupConfirm = () => {
  const { timeLeft, setTimeLeft } = useCountdown(30);
  const [hasError, setHasError] = useState(false);
  const { confirmationEmail, confirmationEmailToken } = useAuthContext();
  const resendConfirmationEmailMutation = useResendConfirmationEmail();

  const { t } = useTranslation();

  const onClickSendAgain = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    setHasError(false);

    // TODO do we need to set confirmationEmailToken to null?
    const res = await resendConfirmationEmailMutation.mutateAsync({
      email: confirmationEmail || '',
      old_invitation_token: confirmationEmailToken || '',
    });

    if (!res?.success) {
      setHasError(true);
    } else {
      setTimeLeft(30);
    }
  };

  return (
    <AuthLayout
      withContactsLink
      title={t('signup2:title')}
      className={styles.wrapper}
    >
      <h2>{t('signup2:header')}</h2>
      <div className={styles.content}>
        <Text>
          {t('signup2:text', { signupConfirmationEmail: confirmationEmail })}
        </Text>
        {Boolean(confirmationEmailToken) && (
          <Button
            onClick={onClickSendAgain}
            className={styles.button}
            disabled={!!timeLeft}
            text={
              timeLeft
                ? t('resend:resendInSeconds', { timeLeft })
                : t('resend:resend')
            }
          />
        )}
        {hasError ? (
          <Text size="small" color="red">
            {t('common:somethingWrong')}
          </Text>
        ) : null}
      </div>
    </AuthLayout>
  );
};

export default PageSignupConfirm;
