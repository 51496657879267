import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { apiClient } from 'features/api';
import type {
  User,
  UserUpdatePayload,
  UpdateUserProfilePayload,
  PortalUserProfileResponseWithOptionalRole,
  ProfileAvatarUpdateResponse,
  RolesResponse,
} from 'features/types';

export const getProfile = async () => {
  const response = await apiClient.get<User>('profile');
  return response.data;
};

export const useProfile = (
  options?: Omit<
    UseQueryOptions<User, unknown, User, ['profile']>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: () => getProfile(),
    staleTime: 60 * 1000,
    ...(options || {}),
  });
};

export const getUsers = async () => {
  const response = await apiClient.get<User[]>('users');
  return response.data;
};

export const useUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });
};

export const getUserById = async (id: string) => {
  const res = await apiClient.get<User>(`users/${id}`);
  return res.data;
};

export const useUser = (id?: string) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => (id ? getUserById(id) : null),
    enabled: !!id,
  });
};

export const disableUserById = async (user_id: string) => {
  const response = await apiClient.delete<{
    message: string;
  }>(`users/${user_id}`);
  return response.data;
};

export const enableUserById = async (user_id: string) => {
  const response = await apiClient.put<{
    message: string;
  }>(`users/${user_id}/enable`);
  return response.data;
};

export const updateUserById = async (id: string, data: UserUpdatePayload) => {
  const res = await apiClient.patch<User>(`users/${id}`, data);
  return res.data;
};

export const updateUserAvatar = async (id: string, data: FormData) => {
  const res = await apiClient.patch<PortalUserProfileResponseWithOptionalRole>(
    `users/${id}/avatar`,
    data
  );
  return res.data;
};

export const updateProfile = async (data: UpdateUserProfilePayload) => {
  try {
    const res =
      await apiClient.patch<PortalUserProfileResponseWithOptionalRole>(
        'profile',
        data
      );
    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const updateProfileAvatar = async (data: FormData) => {
  const res = await apiClient.patch<ProfileAvatarUpdateResponse>(
    'profile/avatar',
    data
  );
  return res.data;
};

export const useRoles = () =>
  useQuery({
    queryKey: ['roles'],
    queryFn: () =>
      apiClient.get<RolesResponse[]>(`roles`).then((res) => res.data),
    staleTime: Infinity,
  });

export const getRoleById = async (roleId: string) => {
  const res = await apiClient
    .get<RolesResponse>(`roles/${roleId}`)
    .then((res) => res.data);
  return res;
};

export const useRoleById = (id?: string) => {
  return useQuery({
    queryKey: ['role', id],
    queryFn: () => (id ? getRoleById(id) : null),
    staleTime: Infinity,
  });
};
