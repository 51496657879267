import { Card } from '@monite/ui';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import Layout, { PageContent, PageHeader } from 'features/app/Layout';

import { useNavigate, useParams } from 'react-router-dom';
import {
  useProjectQuery,
  useProjectSettingsQuery,
} from 'features/projects/api';
import { ROUTES } from 'features/app/consts';

import Form from './Form';
import styles from './styles.module.scss';
import GlobalSpinner from '../../app/GlobalSpinner';

const PageProjectSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: project,
    isLoading: isLoadingProject,
    error: projectError,
  } = useProjectQuery(id);
  useEffect(() => {
    if (!isLoadingProject && !project) {
      navigate(ROUTES.projects);
    }
  }, [isLoadingProject, project]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [project]);
  const {
    data: projectSettings,
    isLoading: isLoadingProjectSettings,
    error: projectSettingsError,
  } = useProjectSettingsQuery(id);

  return (
    <Layout title={t('projects:settings.title')}>
      <PageHeader
        title={t('projects:settings.title')}
        breadcrumbs={[
          { title: t('projects:settings.back'), link: '/projects' },
          { title: project?.name ?? '...' },
        ]}
      />
      <PageContent>
        <Card className={styles.card}>
          {(isLoadingProject || isLoadingProjectSettings) && <GlobalSpinner />}
          {project && projectSettings && (
            <div>
              <Form
                key={project.id}
                projectSettings={projectSettings}
                project={project}
              />
            </div>
          )}
          {(projectError || projectSettingsError) && (
            <div className={styles.cardError}>{t('common:somethingWrong')}</div>
          )}
        </Card>
      </PageContent>
    </Layout>
  );
};

export default PageProjectSettings;
