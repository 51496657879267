import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as RightArrowIcon } from 'assets/icons/forth.svg';
import styles from './styles.module.scss';

type Breadcrumb = {
  title: string;
  link?: string;
  prefix?: string;
};

type PageHeaderProps = {
  title: string;
  backLinkTitle?: string;
  backLinkUrl?: string;
  breadcrumbs?: Breadcrumb[];
  children?: React.ReactNode;
};

const PageHeader = (props: PageHeaderProps) => {
  const { title, breadcrumbs, children } = props;
  return (
    <div className={styles.header}>
      {breadcrumbs && breadcrumbs.length ? (
        <div className={styles.breadcrumbs}>
          {breadcrumbs.map((b, i) => (
            <React.Fragment key={b.title}>
              <span>
                {b.link ? (b.prefix ? `${b.prefix} ` : '') : b.title}
                {b.link ? <Link to={b.link}>{b.title}</Link> : null}
              </span>
              <i>
                <RightArrowIcon width={20} height={20} />
              </i>
            </React.Fragment>
          ))}
        </div>
      ) : null}
      <h1 className={styles.title}>{title}</h1>

      {children ? <aside>{children}</aside> : null}
    </div>
  );
};

export default PageHeader;
