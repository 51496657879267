import React, { useState, useEffect } from 'react';
import { Button, Text } from '@monite/ui';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import useCountdown from 'features/auth/hooks/useCountdown';
import { ROUTES } from 'features/app/consts';
import { useDecryptToken, useRestorePasswordByEmail } from '../queries';
import { useAuthContext } from '../AuthContext';

const PageResetVerify = () => {
  const { timeLeft, setTimeLeft } = useCountdown(0);
  const { token } = useParams();
  const navigate = useNavigate();
  const { setResetPasswordData } = useAuthContext();
  const [email, setEmail] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { data: decryptDataToken, isPending } = useDecryptToken(token);
  const restorePasswordByEmailMutation = useRestorePasswordByEmail();

  useEffect(() => {
    if (token && decryptDataToken) {
      const { email, expired } = decryptDataToken;

      if (email) {
        setEmail(email);

        if (!expired) {
          setResetPasswordData({ token, email });
          navigate(ROUTES.resetPassword);
        }
      }
    }
  }, [token, decryptDataToken]);

  if (!token) {
    return null;
  }

  const onClickSendAgain = async () => {
    setHasError(false);

    if (email) {
      const res = await restorePasswordByEmailMutation.mutateAsync({ email });
      if (res?.success) {
        setTimeLeft(30);
      } else {
        setHasError(true);
      }
    }
  };

  return (
    <AuthLayout loading={isPending} withContactsLink title={t('expired:title')}>
      <h2>{t('expired:title')}</h2>
      <Text>{t('expired:text')}</Text>
      {email ? (
        <div>
          <Button
            disabled={!!timeLeft}
            text={
              timeLeft
                ? t('expired:resendInSeconds', { timeLeft })
                : t('expired:resend')
            }
            onClick={onClickSendAgain}
          />
        </div>
      ) : null}
      {hasError ? (
        <Text size="small" color="red">
          {t('common:somethingWrong')}
        </Text>
      ) : null}
    </AuthLayout>
  );
};

export default PageResetVerify;
