import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import GlobalSpinner from 'features/app/GlobalSpinner';
import AuthLayout from 'features/auth/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'features/app/consts';
import { TokenData } from 'features/types';

import PageJoinSent from 'features/auth/PageJoinSent';
import PageJoin from 'features/auth/PageJoin';
import { useDecryptToken, useResendInvitationEmail } from '../queries';

const PageJoinVerify = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenData, setTokenData] = useState<TokenData | null>(null);
  const [hasError, setHasError] = useState(false);
  const [requestWasReSent, setRequestWasReSent] = useState(false);
  const { data: decryptDataToken, isPending } = useDecryptToken(token);
  const resendInvitationEmailMutation = useResendInvitationEmail();

  useEffect(() => {
    (async () => {
      if (token) {
        if (decryptDataToken && decryptDataToken.email) {
          setTokenData(decryptDataToken);
        } else {
          setHasError(true);
        }
      } else {
        navigate(ROUTES.signup);
      }
    })();
  }, [token, decryptDataToken]);

  if (!token) {
    return null;
  }

  if (isPending) {
    return <GlobalSpinner />;
  }

  if (requestWasReSent && tokenData) {
    return <PageJoinSent tokenData={tokenData} />;
  }

  if (tokenData && !tokenData.expired) {
    return <PageJoin token={token} tokenData={tokenData} />;
  }

  const onClickSendAgain = async () => {
    setHasError(false);

    const res = await resendInvitationEmailMutation.mutateAsync({
      email: tokenData!.email,
      old_invitation_token: token,
    });
    if (res?.success) {
      setRequestWasReSent(true);
    } else {
      setHasError(true);
    }
  };

  return (
    <AuthLayout loading={isPending} withContactsLink title={t('expired:title')}>
      <h2>{t('expired:title')}</h2>
      <Text>{t('join:expired')}</Text>
      {tokenData && tokenData.email ? (
        <div>
          <Button
            data-testid="resend-button"
            text={t('join:requestLink')}
            onClick={onClickSendAgain}
          />
        </div>
      ) : null}
      {hasError ? (
        <Text size="small" color="red">
          {t('common:somethingWrong')}
        </Text>
      ) : null}
    </AuthLayout>
  );
};

export default PageJoinVerify;
