import { FormField, Input, TrashIcon, Button, Text } from '@monite/ui';
import { FormikProps, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import UserRolesSelect from '../UserRolesSelect';

import Modal, { MODAL_VARIANTS } from 'features/app/Modals/Modal';

import styles from './styles.module.scss';

export type User = {
  email: string;
  role: string;
};

export type FormValues = { users: Array<User> };

export type FormProps = {
  emailUsedError: boolean;
} & FormikProps<FormValues>;
const Form = (props: FormProps) => {
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    submitForm,
    errors,
    touched,
    emailUsedError,
    dirty,
    isValid,
    isSubmitting,
    values,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      variant={MODAL_VARIANTS.dialog}
      title={t('users:invite.title')}
      confirmButtonTitle={t('users:invite.submit', {
        count: values?.users?.length || 0,
      })}
      size="md"
      titleProps={{ size: 'h2' }}
      cancelButtonTitle=""
      onConfirm={() => {
        submitForm();
      }}
      confirmButtonProps={{
        disabled: !dirty || !isValid || isSubmitting,
        tooltip:
          !dirty || !isValid
            ? {
                tip: t('users:invite.submitTip'),
                effect: 'float',
              }
            : undefined,
        block: true,
      }}
      footerSeparator={false}
      className={styles.modal}
    >
      <div className={styles.wrapper}>
        <Text align="center">{t('users:invite.subTitle')}</Text>
        <div className={styles.form}>
          <div className={styles.titleRow}>
            <Text color="grey" size="bold">
              {t('users:invite.email')}
            </Text>
            <Text color="grey" size="bold">
              {t('users:invite.role')}
            </Text>
          </div>
          <FieldArray name="users">
            {({ remove, push }) => {
              return (
                <>
                  {values.users.length > 0 &&
                    values.users.map(({ email, role }, index) => {
                      const emailError =
                        //@ts-ignore
                        errors?.users?.[index]?.email;
                      //@ts-ignore
                      const roleError = errors?.users?.[index]?.role;

                      return (
                        <div
                          className={cn(
                            styles.formRow,
                            values.users.length <= 1 && styles.isSingle
                          )}
                          key={index}
                        >
                          <FormField
                            id={`users.${index}.email`}
                            label=""
                            error={
                              emailUsedError
                                ? t('users:invite.emailExists')
                                : emailError && touched?.users?.[index]?.email
                                ? emailError
                                : ''
                            }
                          >
                            <Input
                              id={`users.${index}.email`}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={Boolean(
                                emailError && touched?.users?.[index]?.email
                              )}
                              value={email}
                            />
                          </FormField>

                          <FormField
                            id={`users.${index}.role`}
                            label=""
                            error={
                              roleError && touched?.users?.[index]?.role
                                ? roleError
                                : ''
                            }
                          >
                            <UserRolesSelect
                              onChange={(v) => {
                                setFieldValue(`users.${index}.role`, v.value);
                              }}
                              onBlur={handleBlur}
                              className={styles.select}
                              placeholder=""
                              value={role}
                            />
                          </FormField>

                          <Button
                            type="button"
                            noPadding
                            className={styles.trashButton}
                            color="link"
                            icon={<TrashIcon />}
                            onClick={() => remove(index)}
                            tooltip={{
                              tip: 'Delete',
                            }}
                          />
                        </div>
                      );
                    })}
                  <Button
                    text={'Add person'}
                    className={styles.addButton}
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={() => push({ email: '', role: '' })}
                    block
                    color="blue"
                  />
                </>
              );
            }}
          </FieldArray>
        </div>
      </div>
    </Modal>
  );
};

export default Form;
