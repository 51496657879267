import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  AcceptInvitationPayload,
  AuthPayload,
  RegisterPartnerUserPayload,
} from '../types';
import * as authApi from './api';

export const useSignIn = () =>
  useMutation({
    mutationFn: (data: AuthPayload) => authApi.signIn(data),
  });

export const useInvalidateToken = () =>
  useMutation({
    mutationFn: () => authApi.invalidateToken(),
  });

export const useRestorePassword = () =>
  useMutation({
    mutationFn: (data: { email: string }) => authApi.restorePassword(data),
  });

export const useDecryptToken = (token?: string) =>
  useQuery({
    queryKey: ['decryptToken'],
    queryFn: () => {
      if (token) return authApi.decryptToken({ token });
    },
    enabled: !!token,
  });

export const useActivateEmailByToken = () =>
  useMutation({
    mutationFn: (data: { token: string }) => authApi.activateEmailByToken(data),
  });

export const useResendRegistrationEmail = () =>
  useMutation({
    mutationFn: (data: { email: string; old_invitation_token: string }) =>
      authApi.resendRegistrationEmail(data),
  });

export const useAcceptInvitation = () =>
  useMutation({
    mutationFn: (data: AcceptInvitationPayload) =>
      authApi.acceptInvitation(data),
  });

export const useSignUp = () =>
  useMutation({
    mutationFn: (data: RegisterPartnerUserPayload) => authApi.signUp(data),
  });

export const useResendConfirmationEmail = () =>
  useMutation({
    mutationFn: (data: { email: string; old_invitation_token: string }) =>
      authApi.resendRegistrationEmail(data),
  });

export const useRestorePasswordByEmail = () =>
  useMutation({
    mutationFn: (data: { email: string }) => authApi.restorePassword(data),
  });

export const useChangePassword = () =>
  useMutation({
    mutationFn: (data: { new_password: string; token: string }) =>
      authApi.changePassword(data),
  });

export const useResendInvitationEmail = () =>
  useMutation({
    mutationFn: (data: { email: string; old_invitation_token: string }) =>
      authApi.resendInvitationEmail(data),
  });
