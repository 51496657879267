import React from 'react';
import { Spinner } from '@monite/ui';

import styles from './styles.module.scss';

const GlobalSpinner = () => {
  return (
    <div className={styles.spinner}>
      <Spinner pxSize={32} />
    </div>
  );
};

export default GlobalSpinner;
