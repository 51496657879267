import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Seo from 'features/app/Seo';

import GlobalSpinner from 'features/app/GlobalSpinner';

import logo from 'assets/logo/logo.svg';
import styles from './styles.module.scss';
import HelperLink from './HelperLink';
import { useTranslation } from 'react-i18next';

type AuthLayoutProps = {
  children: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  withContactsLink?: boolean;
  loading?: boolean;
  title?: string;
};

const AuthLayout = ({
  children,
  className,
  withContactsLink,
  loading,
  title,
}: AuthLayoutProps) => {
  const { t } = useTranslation();

  if (loading) {
    return <GlobalSpinner />;
  }

  return (
    <div className={cn(styles.layout, className)}>
      <Seo title={title} />
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Link to="/">
            <img src={logo} alt="monite" />
          </Link>
        </div>
        <div className={styles.content}>{children}</div>
        {withContactsLink ? (
          <div>
            <HelperLink
              secondary
              text={t('auth:contactsText')}
              linkText={t('auth:contactsLink')}
              linkUrl="mailto:support@monite.com"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AuthLayout;
