import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { QueryClient } from '@tanstack/react-query';

import { APP_BASE_URL, SCREEN_TEST_MODE } from 'features/app/consts';

export const apiClient = axios.create({
  baseURL: APP_BASE_URL,
});

export const setHeaderToken = (token: string) => {
  apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  delete apiClient.defaults.headers.common.Authorization;
};

apiClient.interceptors.request.use((config) => {
  Object.assign(config, {
    headers: {
      ...(config.headers || {}),
      'x-monite-version': '2023-04-12',
      'x-service-name': 'partnerportal',
      'x-request-id': uuidv4(),
    },
  });
  return {
    ...config,
  };
});

export const queryClient = new QueryClient({
  ...(process.env.REACT_APP_TESTMODE === SCREEN_TEST_MODE
    ? {
        logger: {
          log: console.log,
          warn: console.warn,
          error: () => {},
        },
      }
    : {}),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      ...(process.env.REACT_APP_TESTMODE === SCREEN_TEST_MODE
        ? { staleTime: Infinity }
        : {}),
    },
  },
});
