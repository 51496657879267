import AuthLayout from 'features/auth/Layout';
import Form from 'features/auth/PageSignup/Form';
import type { TokenData } from 'features/types';

import styles from './styles.module.scss';

type PageJoinProps = {
  token?: string;
  tokenData?: TokenData;
};
const PageJoin = ({ token, tokenData }: PageJoinProps) => {
  return (
    <AuthLayout title="Join account">
      <h2>Join account</h2>
      <div className={styles.content}>
        <Form token={token} tokenData={tokenData} />
      </div>
    </AuthLayout>
  );
};

export default PageJoin;
