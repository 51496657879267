import { Link } from 'react-router-dom';
import { FormikProps } from 'formik';
import { Button, FormField, Input, PasswordInput } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import HelperLink from 'features/auth/Layout/HelperLink';
import { ROUTES } from 'features/app/consts';

export type FormValues = {
  email: string;
  password: string;
};

interface LoginBaseFormProps extends FormikProps<FormValues> {}

const LoginBaseForm = (props: LoginBaseFormProps) => {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    isValid,
    dirty,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <form method="post" onSubmit={handleSubmit}>
        <FormField
          id="email"
          label="Email"
          error={
            errors.email !==
              'The email or password is incorrect. Please retry' &&
            touched.email
              ? errors.email
              : ''
          }
        >
          <Input
            id="email"
            required
            isInvalid={Boolean(errors.email && touched.email)}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        <div>
          <FormField
            id="password"
            label={t('forms:fields.password')}
            error={errors.password && touched.password ? errors.password : ''}
            text={<Link to={ROUTES.reset}>{t('login:forgot')}</Link>}
          >
            <PasswordInput
              id="password"
              required
              isInvalid={Boolean(errors.password && touched.password)}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        </div>

        {isSubmitting ? (
          <Button type="submit" disabled isLoading />
        ) : (
          <Button
            type="submit"
            text={t('login:submit')}
            tooltip={{
              tip:
                (!dirty || !isValid || !values.password || !values.email) &&
                t('login:errors.emptyFieldTooltip'),
            }}
            disabled={!dirty || !isValid || isSubmitting}
          />
        )}

        <HelperLink
          text={t('login:signupText')}
          linkUrl={ROUTES.signup}
          linkText={t('login:signupLink')}
        />
      </form>
    </div>
  );
};

export default LoginBaseForm;
