import React, { useEffect, useState } from 'react';
import { Button, Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import useCountdown from 'features/auth/hooks/useCountdown';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'features/app/consts';
import {
  useActivateEmailByToken,
  useDecryptToken,
  useResendRegistrationEmail,
} from '../queries';
import { useAuthContext } from '../AuthContext';
import GlobalSpinner from '../../app/GlobalSpinner';

const PageSignupVerify = () => {
  const { timeLeft, setTimeLeft } = useCountdown(0);
  const { token } = useParams();
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const { data: decryptDataToken, isPending } = useDecryptToken(token);
  const activateEmailByTokenMutation = useActivateEmailByToken();
  const resendRegistrationEmailMutation = useResendRegistrationEmail();
  const [email, setEmail] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (token) {
        const { email, expired } = decryptDataToken || {};
        if (email) {
          setEmail(email);

          if (!expired) {
            const res = await activateEmailByTokenMutation.mutateAsync({
              token,
            });
            if (res.token) {
              if (res.token === 'DeactivatedByDefault') {
                return void navigate(ROUTES.activationPending);
              } else {
                return void login(res.token);
              }
            }
            if (!res?.token) {
              setHasError(true);
            }
          }
        }
      } else {
        navigate(ROUTES.signup);
      }
      setLoading(false);
    })();
  }, [token, decryptDataToken]);

  if (loading || isPending) {
    return <GlobalSpinner />;
  }

  if (!token) {
    return null;
  }

  const onClickSendAgain = async () => {
    setHasError(false);

    if (email) {
      const res = await resendRegistrationEmailMutation.mutateAsync({
        email,
        old_invitation_token: token,
      });
      if (res?.success) {
        setTimeLeft(30);
      } else {
        setHasError(true);
      }
    }
  };

  return (
    <AuthLayout
      loading={loading || isPending}
      withContactsLink
      title={t('expired:title')}
    >
      <h2>{t('expired:title')}</h2>
      <Text>{t('expired:text')}</Text>
      {email ? (
        <div>
          <Button
            disabled={!!timeLeft}
            text={
              timeLeft
                ? t('expired:resendInSeconds', { timeLeft })
                : t('expired:resend')
            }
            onClick={onClickSendAgain}
          />
        </div>
      ) : null}
      {hasError ? (
        <Text size="small" color="red">
          {t('common:somethingWrong')}
        </Text>
      ) : null}
    </AuthLayout>
  );
};

export default PageSignupVerify;
