import { AxiosError } from '../../types';
import { toast } from 'react-toastify';
import type { TFunction } from 'react-i18next';

export function projectFormResponseErrorHandling(
  error: AxiosError,
  t: TFunction,
  setFieldError: (fieldName: string, message: string) => void
) {
  if (isMultipleProjectsCreationNotAllowedError(error)) {
    toast.error(t('projects:errors.MultipleProjectsCreation'));
    return;
  }

  const handledErrors = getHandledErrors(error, t);

  if (handledErrors.length) {
    return handledErrors.forEach(([fieldName, errorMessage]) => {
      setFieldError(fieldName, errorMessage);
    });
  }
  toast.error(t('common:somethingWrong'));
}

function getHandledErrors(error: AxiosError, t: TFunction) {
  const errors: Array<[fieldName: string, errorMessage: string]> = [];

  if (isProjectNameExistsError(error)) {
    errors.push(['name', t('projects:form.nameExists')]);
  }

  if (isProjectNameMaxLengthError(error)) {
    errors.push([
      'name',
      t('projects:form.nameMaxLength', {
        maxLength: error?.response?.data?.detail?.[0]?.ctx?.limit_value,
      }),
    ]);
  }

  if (isProjectSettingsCurrencyError(error)) {
    errors.push(['currency', t('projects:form.wrongCurrency')]);
  }

  return errors;
}

function isProjectNameExistsError<
  ProjectNameMaxLengthError extends {
    error?: { message?: 'Project with this name already exists.' };
  }
>(error: AxiosError): error is AxiosError<ProjectNameMaxLengthError> {
  const errorMessage = (error.response?.data as ProjectNameMaxLengthError)
    ?.error?.message;

  return Boolean(
    errorMessage &&
      errorMessage.includes('name') &&
      errorMessage.includes('exists')
  );
}

function isMultipleProjectsCreationNotAllowedError<
  ProjectNameMaxLengthError extends {
    error?: {
      message?: 'Creating multiple projects is not allowed at this time.';
    };
  }
>(error: AxiosError): error is AxiosError<ProjectNameMaxLengthError> {
  const errorMessage = (error.response?.data as ProjectNameMaxLengthError)
    ?.error?.message;

  return Boolean(
    errorMessage &&
      errorMessage.includes('Creating') &&
      errorMessage.includes('not allowed')
  );
}

function isProjectNameMaxLengthError<
  ProjectNameMaxLengthError extends {
    detail?: {
      loc: ['body', 'name'];
      msg: string;
      type: 'value_error.any_str.max_length';
      ctx: {
        limit_value: number;
      };
    }[];
  }
>(error: AxiosError): error is AxiosError<ProjectNameMaxLengthError> {
  return (
    (error.response?.data as ProjectNameMaxLengthError)?.detail?.some?.(
      (item) =>
        item?.type === 'value_error.any_str.max_length' &&
        item?.loc[1] === 'name'
    ) || false
  );
}

function isProjectSettingsCurrencyError<
  ProjectNameMaxLengthError extends {
    detail?: {
      loc: ['body', 'project_settings', 'currency'];
      msg: string;
      type: 'type_error.enum';
      ctx: {
        limit_value: number;
      };
    }[];
  }
>(error: AxiosError): error is AxiosError<ProjectNameMaxLengthError> {
  return (
    (error.response?.data as ProjectNameMaxLengthError)?.detail?.some?.(
      (item) => item?.type === 'type_error.enum' && item?.loc[2] === 'currency'
    ) || false
  );
}
