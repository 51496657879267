import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 930;
  top: 0;
  right: 0;
  height: 100vh;
  width: 80vw;
  max-width: 604px;
  background: white;
  box-shadow: -8px 0px 16px 0px #00000014;

  display: flex;
  flex-direction: column;
`;

const Backdrop = styled.div`
  opacity: 0;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 920;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
`;

type SidebarProps = {
  children: React.ReactNode;
  onClickBackdrop?: () => void;
};
const Sidebar = ({ onClickBackdrop, children }: SidebarProps) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
      <Backdrop onClick={onClickBackdrop} />
    </>
  );
};

export default Sidebar;
