import React from 'react';
import { Button, Card, Table } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'assets/icons/plus2.svg';

import Layout, { PageContent, PageHeader } from 'features/app/Layout';
import { useUsers } from 'features/users/api';
import { Can, RoleActions, RoleSubjects } from 'features/roleModel';
import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';

import UserRow from './UserRow';
import styles from './styles.module.scss';

const PageUsers = () => {
  const { t } = useTranslation();
  const { setModal } = useModalContext();

  const { data: users } = useUsers();

  const onClickInvite = () => {
    setModal({
      key: ModalKeys.INVITE_MODAL,
    });
  };

  return (
    <Layout title={t('users:list.title')}>
      <PageHeader title={t('users:list.title')}>
        <Can I={RoleActions.CREATE} a={RoleSubjects.USER}>
          <Button
            onClick={onClickInvite}
            leftIcon={<PlusIcon width={24} height={24} />}
            text={t('users:list.invite')}
          />
        </Can>
      </PageHeader>
      <PageContent>
        <Card p={3} pt={0}>
          <div className={styles.table}>
            <Table>
              <thead>
                <tr>
                  <th>{t('tables:columns.name')}</th>
                  <th>{t('tables:columns.role')}</th>
                  <th>{t('tables:columns.email')}</th>
                  <th>{t('tables:columns.status')}</th>
                  <Can I={RoleActions.UPDATE} a={RoleSubjects.USER}>
                    <th>{t('tables:columns.actions')}</th>
                  </Can>
                </tr>
              </thead>
              <tbody>
                {(users || []).map((user) => (
                  <UserRow key={user.id} data={user} />
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </PageContent>
    </Layout>
  );
};

export default PageUsers;
