import { apiClient } from 'features/api';
import type {
  TokenData,
  AuthPayload,
  AuthResponse,
  MessageResponse,
  AcceptInvitationPayload,
  SetPasswordPayload,
  PortalUserProfileResponseWithOptionalRole,
  RegisterPartnerUserPayload,
} from 'features/types';

type PendingAuthResponse =
  | AuthResponse
  | {
      // On the sandbox and production, the response contains
      // the alternative structure with the 'deactivated_by_default' field
      // and `auth.access_token === 'DeactivatedByDefault'` set
      deactivated_by_default: true;
      auth: {
        access_token: 'DeactivatedByDefault';
        token_type: string;
      };
    };

export const signIn = async (data: AuthPayload) => {
  try {
    const res = await apiClient.post<PendingAuthResponse>('auth/token', data);

    return {
      success: true,
      token: res.data?.auth?.access_token || null,
    };
  } catch (e: any) {
    if (e?.response?.data?.error?.message) {
      return {
        success: false,
        error: e?.response?.data?.error?.message || null,
      };
    } else if (e?.response?.data.detail[0].msg) {
      return {
        success: false,
        error: e?.response?.data.detail[0].msg || null,
      };
    } else {
      return {
        success: false,
        error: e?.message,
      };
    }
  }
};

// TODO: check types when register handlers will be present in API
export const signUp = async (data: RegisterPartnerUserPayload) => {
  try {
    const res = await apiClient.post<PortalUserProfileResponseWithOptionalRole>(
      'register',
      data
    );
    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    if (e?.response?.data?.error?.message) {
      return {
        success: false,
        error: e?.response?.data?.error?.message || null,
      };
    } else if (e?.response?.data.detail[0].msg) {
      return {
        success: false,
        invalidWebsite: e?.response?.data.detail[0].msg || null,
      };
    } else {
      return {
        success: false,
        error: e.message,
      };
    }
  }
};

// TODO: check types when register handlers will be present in API
export const resendRegistrationEmail = async (data: {
  email: string;
  old_invitation_token: string;
}) => {
  try {
    const res = await apiClient.post<MessageResponse>(
      'register/resend_registration_email',
      data
    );

    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const resendInvitationEmail = async (data: {
  email: string;
  old_invitation_token: string;
}) => {
  try {
    const res = await apiClient.post<MessageResponse>(
      'auth/resend_invitation_email',
      data
    );

    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const acceptInvitation = async (data: AcceptInvitationPayload) => {
  try {
    const res = await apiClient.post<PendingAuthResponse>(
      'auth/accept_invitation',
      data
    );

    return {
      success: true,
      token: res.data?.auth?.access_token || null,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const activateEmailByToken = async (params: { token: string }) => {
  try {
    const res = await apiClient.post<PendingAuthResponse>(
      'auth/activate',
      {},
      { params }
    );

    return {
      success: true,
      token: res.data?.auth?.access_token || null,
      deactivated_by_default: res.data.deactivated_by_default,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const restorePassword = async (data: { email: string }) => {
  try {
    const res = await apiClient.post<MessageResponse>(
      'auth/forgot_password',
      data
    );

    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const changePassword = async (data: SetPasswordPayload) => {
  try {
    const res = await apiClient.post<MessageResponse>(
      'auth/set_password',
      data
    );

    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};

export const decryptToken = async (params: { token: string }) => {
  try {
    const res = await apiClient.get<TokenData>('auth/decrypt_token', {
      params,
    });

    return res.data || null;
  } catch (e) {
    return null;
  }
};

export const invalidateToken = async () => {
  try {
    const res = await apiClient.delete('auth/logout');

    return {
      success: true,
      data: res.data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: e?.response?.data?.error?.message || null,
    };
  }
};
