import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Project, ProjectSettings, ProjectUpdateRequest } from 'features/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateProjectById } from 'features/projects/api';

import BaseForm, { FormValues } from './Form';
import { AxiosError } from 'axios';
import { projectFormResponseErrorHandling } from '../../shared/projectFormResponseErrorHandling';

const getValidationSchema = () => {
  const validators = {
    name: Yup.string().required(),
    currency: Yup.string().required(),
  };

  return Yup.object().shape(validators);
};

type FormProps = {
  project: Project;
  projectSettings: ProjectSettings;
};

const Form = ({ project, projectSettings }: FormProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const updateProjectMutation = useMutation({
    mutationFn: (data: ProjectUpdateRequest) =>
      updateProjectById(project.id, data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: ['project_settings', project.id],
      });
      await queryClient.cancelQueries({ queryKey: ['projects', project.id] });

      const previousSettings = queryClient.getQueryData([
        'project_settings',
        project.id,
      ]);

      const previousProject = queryClient.getQueryData(['project', project.id]);

      queryClient.setQueryData(
        ['project_settings', project.id],
        (previous) => ({
          ...(previous as object | undefined),
          ...data.project_settings,
        })
      );
      queryClient.setQueryData(['project', project.id], (previous) => ({
        ...(previous as object | undefined),
        name: data.name,
      }));

      return { previousSettings, previousProject };
    },
    onError: (error: AxiosError, data, context) => {
      queryClient.setQueryData(
        ['project', project.id],
        context?.previousProject ?? {}
      );

      queryClient.setQueryData(
        ['project_settings', project.id],
        context?.previousSettings ?? {}
      );

      if (formikRef.current) {
        projectFormResponseErrorHandling(
          error,
          t,
          formikRef.current.setFieldError.bind(formikRef.current.setFieldError)
        );
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['project_settings', project.id],
      });
      queryClient.invalidateQueries({ queryKey: ['projects', project.id] });
      if (data) {
        toast(t('projects:settings.saved'));
      }
    },
  });

  const onFormSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    if (!formData.currency) {
      return;
    }

    updateProjectMutation.mutate({
      name: formData.name,
      project_settings: {
        currency: formData.currency,
      },
    });
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm {...props} />;
  };

  const resolveInitialValues = (): FormValues => {
    return {
      name: project.name || '',
      currency: projectSettings.currency || '',
    };
  };

  return (
    <Formik
      initialValues={resolveInitialValues()}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema()}
      innerRef={formikRef}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;
