import React, { useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Text, Avatar } from '@monite/ui';
import cn from 'classnames';
import { useClickAway, useLockBodyScroll } from 'react-use';
import { useTranslation } from 'react-i18next';

import MenuLink from 'features/app/Layout/MenuLink';
import GlobalSpinner from 'features/app/GlobalSpinner';
import { ROUTES } from 'features/app/consts';
import { useProfile } from 'features/users/api';
import Seo from 'features/app/Seo';
import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';
import logo from 'assets/logo/logo.svg';
import upworkLogo from 'assets/logos/upwork_logo.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external_link.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-down-3.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';

import styles from './styles.module.scss';

export { default as PageHeader } from './PageHeader';
export { default as PageContent } from './PageContent';

type LayoutProps = {
  children?: React.ReactNode;
  loading?: boolean;
  title?: string;
};

const Layout = (props: LayoutProps) => {
  const { title, children, loading } = props;

  const { t } = useTranslation();
  const { setModal } = useModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [shownSidebar, toggleSidebar] = useState(false);
  const asideRef = useRef(null);

  useLockBodyScroll(shownSidebar);

  useClickAway(asideRef, () => {
    if (shownSidebar) {
      toggleSidebar(false);
    }
  });

  const { data: profile, isPending } = useProfile();

  if (loading || isPending) {
    return <GlobalSpinner />;
  }
  const onClickMenuIcon = () => {
    toggleSidebar(true);
  };

  const onClickProfile = () => {
    setModal({
      key: ModalKeys.USER_SIDEBAR,
      props: {
        id: profile!.id,
      },
    });
  };

  return (
    <>
      <Seo title={title} />
      <div className={styles.layout}>
        <div className={styles.header}>
          <div className={styles.leftCol}>
            <Link to="/">
              <img src={logo} alt="monite" />
            </Link>
          </div>
          <div className={styles.rightCol}>
            {profile ? (
              <Avatar
                as="button"
                url={profile.avatar?.url}
                name={profile.fullname}
                className={styles.profile}
                onClick={onClickProfile}
              />
            ) : null}
          </div>
          <div className={styles.mobile}>
            <div>
              {profile ? (
                <Avatar
                  as={Link}
                  name={profile.company?.name}
                  to="/"
                  style={{ backgroundImage: `url(${upworkLogo}` }}
                  onlyLetter
                />
              ) : null}
              <Text size="h3" as="h1" color="black">
                {title}
              </Text>
            </div>
            <Button
              color="black"
              noPadding
              icon={<DragIcon width={24} height={24} />}
              onClick={onClickMenuIcon}
            />
          </div>
        </div>
        <div
          ref={asideRef}
          className={cn(styles.asideCol, { [styles.shown]: shownSidebar })}
        >
          {profile?.company?.name ? (
            <MenuLink
              as="button"
              className={styles.currentProjectMenuLink}
              leftIcon={
                <Avatar
                  url={profile?.company.logo?.url}
                  name={profile.company.name}
                  size={32}
                />
              }
              rightIcon={<ArrowRightIcon width={24} height={24} />}
              onClick={(e: React.BaseSyntheticEvent) => {
                e.preventDefault();
                navigate(
                  ROUTES.companyEdit.replace(':id', profile.company!.id)
                );
              }}
            >
              {profile.company.name}
            </MenuLink>
          ) : null}
          <nav>
            <MenuLink
              to={ROUTES.projects}
              active={location.pathname.startsWith(ROUTES.projects)}
            >
              {t('aside:menu.projects')}
            </MenuLink>
            <MenuLink
              to={ROUTES.users}
              active={location.pathname.startsWith(ROUTES.users)}
            >
              {t('aside:menu.users')}
            </MenuLink>
          </nav>
          <MenuLink
            rightIcon={<ExternalLinkIcon width={24} height={24} />}
            href="https://docs.monite.com"
            className={styles.footerMenuLink}
          >
            {t('aside:menu.docs')}
          </MenuLink>
        </div>
        <div className={styles.contentCol}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      <div className={cn(styles.backdrop, { [styles.shown]: shownSidebar })} />
    </>
  );
};

export default Layout;
