import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Box, BoxProps } from '@monite/ui';

import styles from './styles.module.scss';

type MenuLinkProps = {
  children: React.ReactNode;
  className?: string;
  rightIconSrc?: string;
  rightIcon?: React.ReactNode;
  leftLogoIconSrc?: string;
  leftIcon?: React.ReactNode;
  to?: string;
  href?: string;
  innerRef?: React.Ref<any>;
  active?: boolean;
  marginBottom?: number;
  onClick?: (e: React.BaseSyntheticEvent) => void;
} & BoxProps;

const MenuLink = (props: MenuLinkProps) => {
  const {
    children,
    rightIconSrc,
    leftLogoIconSrc,
    rightIcon,
    leftIcon,
    className,
    to,
    href,
    innerRef,
    onClick,
    as,
    active,
  } = props;

  const BoxLink = Box as any as React.FC<MenuLinkProps>;

  return (
    <BoxLink
      className={cn(styles.menuLink, className, {
        [styles.noLeftIcon]: !leftLogoIconSrc && !leftIcon,
        [styles.active]: active,
      })}
      as={as || (to ? Link : 'a')}
      to={to}
      href={href}
      onClick={onClick}
      ref={innerRef}
      {...(href ? { target: '_blank' } : {})}
    >
      {leftLogoIconSrc ? (
        <i
          className={styles.menuLinkLeftLogoIcon}
          style={{ backgroundImage: `url(${leftLogoIconSrc})` }}
        />
      ) : null}
      {leftIcon ? (
        <i className={styles.menuLinkLeftLogoIcon}>{leftIcon}</i>
      ) : null}
      <span>{children}</span>
      {rightIconSrc ? (
        <i
          className={styles.menuLinkRightIcon}
          style={{ backgroundImage: `url(${rightIconSrc})` }}
        />
      ) : null}
      {rightIcon ? (
        <i className={styles.menuLinkRightIcon}>{rightIcon}</i>
      ) : null}
    </BoxLink>
  );
};

export default MenuLink;
