import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import { QueryClientProvider } from '@tanstack/react-query';
import { THEMES } from '@monite/ui';

import App from 'features/app/App';
import { queryClient } from 'features/api';
import { ModalContextProvider, Modal } from 'features/app/Modals/ModalContext';

import { AuthContextProvider } from './features/auth/AuthContext';

type RootProps = {
  initialState?: { isAuth?: boolean; token?: string; app?: { modal?: Modal } };
  initialData?: Record<string, any>;
};
const Root = ({ initialState, initialData }: RootProps) => {
  if (initialData) {
    (initialData || []).forEach((data: { key: string[]; value: any }) => {
      queryClient.setQueryData(data.key, data.value);
    });
  }

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <BrowserRouter>
          <ThemeProvider theme={THEMES.default}>
            <AuthContextProvider
              initialValues={{
                isAuth: initialState?.isAuth,
                token: initialState?.token,
              }}
            >
              <ModalContextProvider initialModal={initialState?.app?.modal}>
                <App />
              </ModalContextProvider>
            </AuthContextProvider>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default Root;
