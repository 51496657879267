import { Button, Card } from '@monite/ui';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GearIcon } from 'assets/icons/gear.svg';

import type { Project as ProjectType } from 'features/types';
import { Can, RoleSubjects, RoleActions } from 'features/roleModel';

import styles from './styles.module.scss';

type ProjectProps = {
  data: ProjectType;
  isHighlight?: boolean;
};
const Project = ({ data, isHighlight }: ProjectProps) => {
  const { t } = useTranslation();
  const [isHighlighted, setIsHighlighted] = useState(isHighlight);
  useEffect(() => {
    ReactTooltip.rebuild();

    if (isHighlighted) {
      setTimeout(() => {
        setIsHighlighted(false);
      }, 2000);
    }
  }, []);

  return (
    <Card
      className={cn(styles.project, {
        [styles.isHighlighted]: isHighlighted,
      })}
    >
      <div className={styles.title}>{data.name}</div>
      <div className={styles.actions}>
        <Can I={RoleActions.CREATE} a={RoleSubjects.SECRET}>
          <Button
            color="secondary"
            text={t('projects:list.keys')}
            as={Link}
            to={`/projects/${data.id}/keys`}
          />
        </Can>
        <Can I={RoleActions.UPDATE} a={RoleSubjects.PROJECT}>
          <Button
            as={Link}
            to={`/projects/${data.id}/settings`}
            color="secondary"
            icon={<GearIcon width={20} height={20} />}
            tooltip={{
              tip: t('projects:list.settingsTip'),
            }}
          />
        </Can>
      </div>
    </Card>
  );
};

export default Project;
