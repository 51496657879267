import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateCompanyById } from 'features/companies/api';
import { Company, CompanyUpdateRequest } from 'features/types';

const useUpdateCompanyMutation = (company: Company) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: CompanyUpdateRequest) =>
      updateCompanyById(company.id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      toast(t('companies:form.companyChanged'));
    },
    onError: () => {
      let errorMessage = t('forms:defaultError');
      toast(errorMessage);
    },
  });
};

export default useUpdateCompanyMutation;
