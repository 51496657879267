import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { Sidebar } from '@monite/ui';
import { useModalContext } from 'features/app/Modals/ModalContext';

type GlobalSidebarProps = {
  children: React.ReactNode;
};
const GlobalSidebarWrapper = ({ children, ...props }: GlobalSidebarProps) => {
  const { modal, setModal } = useModalContext();
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!modal) {
    return null;
  }

  const onClickBackdrop = () => {
    setModal(null);
  };

  return (
    <Sidebar onClickBackdrop={onClickBackdrop} {...props}>
      {children}
    </Sidebar>
  );
};

export default GlobalSidebarWrapper;
