import { defineAbilityFor } from 'features/roleModel';
import { useProfile, useRoleById } from 'features/users/api';

const useRoleAbility = () => {
  const { data: user } = useProfile();

  const { data: role } = useRoleById(user?.role_id);

  if (role) {
    return defineAbilityFor(role);
  }
  return defineAbilityFor();
};

export default useRoleAbility;
