import AuthLayout from 'features/auth/Layout';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const PageDeletedAccount = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout title={t('users:deleted.title')} className={styles.wrapper}>
      <h2>{t('users:deleted.title')}</h2>
      <div className={styles.content}>{t('users:deleted.content')}</div>
    </AuthLayout>
  );
};

export default PageDeletedAccount;
