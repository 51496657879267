import AuthLayout from 'features/auth/Layout';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const PageTermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout
      title={t('signup:termsAndConditionsTitle')}
      className={styles.wrapper}
    >
      <h2>{t('signup:termsAndConditionsTitle')}</h2>
      <div className={styles.content}>
        {/* {t('signup:termsAndConditionsContent')} */}
        <p>1. Allgemeines, Vertragspartner und Geltungsbereich</p>
        <p>
          1.1. Diese Allgemeinen Geschäftsbedingungen finden Anwendung auf die
          zwischen der Monite GmbH, Rheinsberger Str. 76/77, 10115 Berlin
          (nachfolgend „Monite“ genannt) mit ihren Kunden im Zusammenhang mit
          der Erbringung ihrer Dienste geschlossenen Verträge und werden
          Bestandteil des jeweiligen Vertrages. Die Dienste von Monite bestehen
          in der Bereitstellung einer Management-Software für die Buchhaltung
          und Verwaltung für kleine und mittlere Unternehmen (die „Dienste“).
          Die Dienste werden im Rahmen einer Webapplikation (das „Portal“) zur
          Verfügung gestellt.
        </p>
        <br />
        <p>
          1.2. Für Verträge im Zusammenhang mit den Diensten gelten
          ausschließlich diese Allgemeinen Geschäftsbedingungen. Abweichende
          oder entgegenstehende Bedingungen werden von Monite nur dann
          anerkannt, wenn Monite ihnen ausdrücklich schriftlich zugestimmt hat.
        </p>
        <br />
        <p>
          1.3. Die Dienste von Monite richten sich ausschließlich an Unternehmer
          im Sinne des § 14 BGB. Monite kann daher vor Vertragsschluss
          verlangen, dass der potenzielle Kunde seine Unternehmereigenschaft
          nachweist, zB. durch Angabe Ihrer UST-ID-Nr. oder durch sonstige
          geeignete Nachweise. Die für den Nachweis erforderlichen Informationen
          sind vom Kunden vollständig und wahrheitsgemäß anzugeben.
        </p>
        <br />
        <p>
          1.4. Diese Allgemeinen Geschäftsbedingungen gelten in der gültigen
          Fassung, die dem Kunden zuletzt in Textform mitgeteilt wurde, auch für
          gleichartige Verträge, die in der Zukunft abgeschlossen werden, ohne
          dass bei jedem Vertragsabschluss wieder auf die Einbeziehung der
          Allgemeinen Geschäftsbedingungen hingewiesen werden muss.
          Gegenforderungen durch den Kunden ist nur zulässig, soweit diese
          Gegenansprüche unbestritten oder rechtskräftig festgestellt sind.
        </p>
      </div>
    </AuthLayout>
  );
};

export default PageTermsAndConditions;
