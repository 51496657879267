import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateProfileAvatar } from 'features/users/api';

const useUpdateProfileAvatarMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: FormData) => updateProfileAvatar(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      toast(t('users:form.profileChanged'));
    },
    onError: () => {
      let errorMessage = t('forms:fileError');
      toast(errorMessage);
    },
  });
};

export default useUpdateProfileAvatarMutation;
