import { FormikProps } from 'formik';
import { Button, FormField, PasswordInput } from '@monite/ui';
import { useTranslation } from 'react-i18next';

export type FormValues = {
  password: string;
  repeatPassword: string;
};

interface LoginBaseFormProps extends FormikProps<FormValues> {}

const LoginBaseForm = (props: LoginBaseFormProps) => {
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    dirty,
    isValid,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <form method="post" onSubmit={handleSubmit}>
        <FormField
          id="password"
          label={t('forms:fields.password')}
          error={
            errors.password && touched.password
              ? t('forms:invalidPassword')
              : ''
          }
          text={t('forms:passwordTip')}
        >
          <PasswordInput
            id="password"
            required
            isInvalid={Boolean(errors.password && touched.password)}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        <FormField
          id="repeatPassword"
          label={t('forms:fields.repeatPassword')}
          error={
            errors.repeatPassword && touched.repeatPassword
              ? errors.repeatPassword
              : ''
          }
        >
          <PasswordInput
            id="repeatPassword"
            required
            isInvalid={Boolean(errors.repeatPassword && touched.repeatPassword)}
            value={values.repeatPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        {isSubmitting ? (
          <Button type="submit" disabled isLoading />
        ) : (
          <Button
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
            text={t('forgot4:submit')}
          />
        )}
      </form>
    </div>
  );
};

export default LoginBaseForm;
