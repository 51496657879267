import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateProfile } from 'features/users/api';
import { UpdateUserProfilePayload, User } from 'features/types';

const useUpdateProfileMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: UpdateUserProfilePayload) => updateProfile(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['profile'] });
      const previousData = queryClient.getQueryData<User>(['profile']);

      queryClient.setQueryData(['profile'], () => ({
        ...(previousData || {}),
        fullname: data.fullname,
      }));

      return { previousData };
    },
    onError: (err, data, context: any) => {
      queryClient.setQueryData(['profile'], context.previousData);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      toast(t('users:form.profileChanged'));
      // TODO: Password for your account was successfully changed.
    },
  });
};

export default useUpdateProfileMutation;
