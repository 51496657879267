import { ToastContainer } from 'react-toastify';
import React from 'react';

import styles from './styles.module.scss';

const GlobalToast = () => {
  return (
    <ToastContainer
      className={styles.container}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
      autoClose={5000}
      closeOnClick
      closeButton={false}
      hideProgressBar
      position="bottom-left"
    />
  );
};

export default GlobalToast;
