import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import { ROUTES } from 'features/app/consts';
import { useAuthContext } from '../AuthContext';
import Form from './Form';

import styles from './styles.module.scss';

const PageResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetPasswordData } = useAuthContext();

  const { token } = resetPasswordData || {};

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.reset);
    }
  }, []);

  if (!token) {
    return null;
  }

  return (
    <AuthLayout title={t('forgot4:title')}>
      <h2>{t('forgot4:title')}</h2>
      <div className={styles.content}>
        <Form token={token} />
      </div>
    </AuthLayout>
  );
};

export default PageResetPassword;
