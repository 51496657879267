import Modal, { MODAL_VARIANTS } from 'features/app/Modals/Modal';
import { FormField, Input, Button } from '@monite/ui';
import React, { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import GlobalSpinner from 'features/app/GlobalSpinner';
import { createApiKey } from 'features/apikeys/api';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';

import styles from './styles.module.scss';
import { useModalContext } from '../../app/Modals/ModalContext';

// TODO get from openapi generated
type Secret = {
  name: string;
  client_id: string;
  client_secret: string;
  client_secret_mask: string;
};
const ModalCreateApiKey = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { setModal } = useModalContext();

  const queryClient = useQueryClient();

  const [data, setData] = useState<Secret>();

  const createApiKeyMutation = useMutation({
    mutationFn: (data: any) => createApiKey(data),
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: ['apiKeys'] });
      setData(data);
    },
    onError: () => {
      toast.error(t('common:somethingWrong'));
    },
  });

  useEffect(() => {
    createApiKeyMutation.mutate({
      project_id: id,
      name: '',
    });
  }, []);

  const [, copyToClipboard] = useCopyToClipboard();

  const onClickCopySecretIcon = () => {
    if (data?.client_secret) {
      copyToClipboard(data?.client_secret);
      toast(t('apikeys:create.secretCopied'));
    }
  };

  const onClickCopyClientIdIcon = () => {
    if (data?.client_id) {
      copyToClipboard(data?.client_id);
      toast(t('apikeys:create.clientIdCopied'));
    }
  };

  return createApiKeyMutation.isPending ? (
    <GlobalSpinner />
  ) : (
    <Modal
      variant={MODAL_VARIANTS.dialog}
      title={t('apikeys:create.title')}
      confirmButtonTitle={t('apikeys:create.confirm')}
      cancelButtonTitle=""
      onConfirm={() => {
        if (data?.client_secret) {
          copyToClipboard(data?.client_secret);
          toast(t('apikeys:create.created'));
          setModal(null);
        }
      }}
    >
      <div className={styles.content}>
        <FormField id="apiKey" label={t('apikeys:create.id')}>
          <Input
            id="apiKey"
            required
            value={data?.client_id}
            renderAddonIcon={() => (
              <Button
                onClick={onClickCopyClientIdIcon}
                color="grey"
                hover="black"
                icon={<CopyIcon width={20} height={20} />}
              />
            )}
          />
        </FormField>
        <FormField id="apiKey" label={t('apikeys:create.label')}>
          <Input
            id="apiKey"
            required
            value={data?.client_secret}
            renderAddonIcon={() => (
              <Button
                onClick={onClickCopySecretIcon}
                color="grey"
                hover="black"
                icon={<CopyIcon width={20} height={20} />}
              />
            )}
          />
        </FormField>

        <div className={styles.alert}>
          <div>
            <AlertIcon width={20} height={20} />
          </div>
          <div>{t('apikeys:create.alert')}</div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateApiKey;
