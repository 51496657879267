import { Routes, Route, Navigate } from 'react-router-dom';

import PageProjects from 'features/projects/PageProjects';
import PageApiKeys from 'features/apikeys/PageApiKeys';
import PageProjectSettings from 'features/projects/PageProjectSettings';
import PageUsers from 'features/users/PageUsers';
import PageCompanyEdit from 'features/companies/PageCompanyEdit';
import PageReset from 'features/auth/PageReset';
import PageResetVerify from 'features/auth/PageResetVerify';
import PageResetPassword from 'features/auth/PageResetPassword';

import { ROUTES } from 'features/app/consts';
import { AbilityContext } from 'features/roleModel';
import useRoleAbility from 'features/users/hooks/useRoleAbility';
import GlobalModal from '../../Modals';

const AuthorizedArea = () => {
  const ability = useRoleAbility();

  return (
    <AbilityContext.Provider value={ability}>
      <GlobalModal />
      <Routes>
        <Route path={ROUTES.projects} element={<PageProjects />} />
        <Route path={ROUTES.apiKeys} element={<PageApiKeys />} />
        <Route
          path={ROUTES.projectSettings}
          element={<PageProjectSettings />}
        />
        <Route path={ROUTES.users} element={<PageUsers />} />
        <Route path={ROUTES.reset} element={<PageReset />} />
        <Route path={ROUTES.resetPassword} element={<PageResetPassword />} />
        <Route path={ROUTES.resetToken} element={<PageResetVerify />} />
        <Route path={ROUTES.companyEdit} element={<PageCompanyEdit />} />
        <Route path="*" element={<Navigate to={ROUTES.projects} />} />
      </Routes>
    </AbilityContext.Provider>
  );
};

export default AuthorizedArea;
