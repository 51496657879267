import React from 'react';
import { Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';

import styles from './styles.module.scss';

const AccountActivationPending = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout
      withContactsLink
      title={t('accountActivationPending:title')}
      className={styles.wrapper}
    >
      <h2>{t('accountActivationPending:header')}</h2>
      <div className={styles.content}>
        <Text>{t('accountActivationPending:text')}</Text>
      </div>
    </AuthLayout>
  );
};

export default AccountActivationPending;
