import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import LoginForm from './Form';

import styles from './styles.module.scss';

const PageLogin = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout title={t('login:title')}>
      <h2>{t('login:title')}</h2>
      <div className={styles.content}>
        <LoginForm />
      </div>
    </AuthLayout>
  );
};

export default PageLogin;
