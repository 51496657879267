import { Card } from '@monite/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import GlobalSpinner from 'features/app/GlobalSpinner';
import Layout, { PageHeader, PageContent } from 'features/app/Layout';
import { useCompany } from 'features/companies/api';
import { ROUTES } from 'features/app/consts';

import Form from './Form';
import { FormValues } from './Form/Form';

import styles from './styles.module.scss';

const PageCompanyEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<FormValues>>(null);

  const { isLoading, data: company } = useCompany(id);

  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && !company) {
      navigate(ROUTES.projects);
    }
  }, [isLoading, company]);

  if (!company && isLoading) {
    return <GlobalSpinner />;
  }

  if (!company) {
    return null;
  }

  return (
    <Layout loading={isLoading} title={t('companies:title')}>
      <PageHeader title={t('companies:title')} />
      <PageContent>
        <Card className={styles.card}>
          <Form company={company} formRef={formRef} />
        </Card>
      </PageContent>
    </Layout>
  );
};

export default PageCompanyEdit;
