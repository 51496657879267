import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'react-i18next';

import type { Company } from 'features/types';
import BaseForm, { FormValues } from './Form';
import React from 'react';
import useUpdateCompanyMutation from 'features/companies/hooks/useUpdateCompanyMutation';

type FormProps = {
  company: Company;
  formRef: React.Ref<any>;
};

const Form = ({ formRef, company }: FormProps) => {
  const { t } = useTranslation();

  const updateCompanyMutation = useUpdateCompanyMutation(company);

  const getValidationSchema = () => {
    const validators = {
      name: Yup.string().required(
        t('forms:errors.required', { field: t('forms:fields.companyName') })
      ),
      email: Yup.string()
        .required(
          t('forms:errors.required', { field: t('forms:fields.email') })
        )
        .email(t('forms:invalidEmail')),
      website: Yup.string()
        .required(
          t('forms:errors.required', {
            field: t('forms:fields.companyWebsite'),
          })
        )
        .url(),
      phone: Yup.string().phone(),
    };

    return Yup.object().shape(validators);
  };

  const onFormSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    updateCompanyMutation.mutate({
      name: formData.name,
      contact_email: formData.email,
      website: formData.website,
      contact_phone: formData.phone,
    });
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm company={company} {...props} />;
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        name: company.name,
        website: company.website || '',
        email: company.contact_email || '',
        phone: company.contact_phone || '',
      }}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema()}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;
