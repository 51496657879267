import { Routes, Route, Navigate } from 'react-router-dom';

import PageLogin from 'features/auth/PageLogin';
import PageSignup from 'features/auth/PageSignup';

import PageSignupVerify from 'features/auth/PageSignupVerify';
import PageSignupConfirm from 'features/auth/PageSignupConfirm';

import PageReset from 'features/auth/PageReset';
import PageResetVerify from 'features/auth/PageResetVerify';
import PageResetPassword from 'features/auth/PageResetPassword';

import PageJoinVerify from 'features/auth/PageJoinVerify';

import PageDeletedAccount from 'features/auth/PageDeletedAccount';

import PageTermsAndConditions from 'features/auth/PageTermsAndConditions';

import { ROUTES } from 'features/app/consts';
import AccountActivationPending from '../../../auth/AccountActivationPending';

const PublicArea = () => {
  return (
    <Routes>
      <Route path={ROUTES.signin} element={<PageLogin />} />
      <Route path={ROUTES.signup} element={<PageSignup />} />
      <Route path={ROUTES.reset} element={<PageReset />} />
      <Route path={ROUTES.resetPassword} element={<PageResetPassword />} />
      <Route path={ROUTES.resetToken} element={<PageResetVerify />} />
      <Route path={ROUTES.confirm} element={<PageSignupConfirm />} />
      <Route
        path={ROUTES.activationPending}
        element={<AccountActivationPending />}
      />
      <Route path={ROUTES.confirmToken} element={<PageSignupVerify />} />
      <Route path={ROUTES.joinToken} element={<PageJoinVerify />} />
      <Route path={ROUTES.deletedAccount} element={<PageDeletedAccount />} />
      <Route
        path={ROUTES.termsAndConditions}
        element={<PageTermsAndConditions />}
      />
      <Route path="*" element={<Navigate to={ROUTES.signin} />} />
    </Routes>
  );
};

export default PublicArea;
