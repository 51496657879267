import { Select, SelectProps } from '@monite/ui';
import { useCurrencies } from 'features/projects/api';

type CurrencySelectProps = {
  value: string;
} & Omit<SelectProps, 'options' | 'value'>;
const CurrencySelect = (props: CurrencySelectProps) => {
  const { data: currencies } = useCurrencies();

  const options = (currencies || []).map((currency) => ({
    label: currency.name,
    value: currency.name,
  }));

  return (
    <Select
      {...props}
      options={options}
      value={options.find((o) => o.value === props.value)}
    />
  );
};

export default CurrencySelect;
