import { FormikProps } from 'formik';
import { Button, FormField, Input, PasswordInput } from '@monite/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAbility } from '@casl/react';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { ROUTES } from 'features/app/consts';
import type { User } from 'features/types';
import { AbilityContext, RoleActions, RoleSubjects } from 'features/roleModel';

import UserRolesSelect from '../UserRolesSelect';

import styles from './styles.module.scss';
import { useRestorePassword } from '../../auth/queries';

export type FormValues = {
  name: string;
  email: string;
  role: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
};

interface BaseFormProps extends FormikProps<FormValues> {
  isProfileEditing?: boolean;
  profile: User;
  showPasswords: boolean;
  togglePasswords: (state: boolean) => void;
  onClickClose: () => void;
}

const BaseForm = (props: BaseFormProps) => {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    isProfileEditing,
    showPasswords,
    togglePasswords,
    onClickClose,
  } = props;

  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);
  const restorePasswordMutation = useRestorePassword();

  const onClickUpdatePassword = () => {
    togglePasswords(true);
  };

  const closeSidebarUser = () => {
    onClickClose();
  };

  const onClickSendLinkToEmail = async (e: React.BaseSyntheticEvent) => {
    closeSidebarUser();
    e.preventDefault();
    await restorePasswordMutation.mutateAsync({
      email: values.email,
    });
    // TODO: do we need to checking response?
    toast(t('forgot2:text', { email: values.email }));
  };

  if (!showPasswords) {
    values.oldPassword = '';
    values.newPassword = '';
    values.repeatPassword = '';
  }

  const renderPasswords = () => {
    if (!isProfileEditing) {
      return null;
    }

    if (showPasswords) {
      return (
        <div className={styles.passwords}>
          <FormField
            id="oldPassword"
            label={t('forms:fields.oldPassword')}
            error={
              errors.oldPassword && touched.oldPassword
                ? (errors.oldPassword === t('forms:invalidOldPasswords') && (
                    <p>
                      {t('forms:invalidOldPasswords1')}
                      <Link
                        onClick={onClickSendLinkToEmail}
                        to={ROUTES.resetPassword}
                      >
                        {' ' + t('forms:restorePassword')}
                      </Link>
                      .
                    </p>
                  )) ||
                  t('forms:invalidPassword')
                : ''
            }
          >
            <PasswordInput
              id="oldPassword"
              required
              isInvalid={Boolean(errors.oldPassword && touched.oldPassword)}
              value={values.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>

          <FormField
            id="newPassword"
            label={t('forms:fields.newPassword')}
            error={
              errors.newPassword && touched.newPassword
                ? errors.newPassword ===
                  t('forms:invalidNewPasswordsMatchOldPasswords')
                  ? t('forms:invalidNewPasswordsMatchOldPasswords')
                  : t('forms:invalidPassword')
                : ''
            }
            text={t('forms:passwordTip')}
          >
            <PasswordInput
              id="newPassword"
              required
              isInvalid={Boolean(errors.newPassword && touched.newPassword)}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>

          <FormField
            id="repeatPassword"
            label={t('forms:fields.repeatPassword')}
            error={
              errors.repeatPassword && touched.repeatPassword
                ? t('forms:invalidConfirmPassword')
                : ''
            }
          >
            <PasswordInput
              id="repeatPassword"
              required
              isInvalid={Boolean(
                errors.repeatPassword && touched.repeatPassword
              )}
              value={values.repeatPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        </div>
      );
    }
    return (
      <Button
        text={t('users:form.updatePassword')}
        color="secondary"
        onClick={onClickUpdatePassword}
      />
    );
  };

  return (
    <form method="post" onSubmit={handleSubmit} className={styles.form}>
      <FormField
        id="name"
        label={t('forms:fields.fullName')}
        error={errors.name && touched.name ? errors.name : ''}
        readOnly={
          !(
            ability.can(RoleActions.UPDATE, RoleSubjects.USER) ||
            (isProfileEditing &&
              ability.can(RoleActions.UPDATE, RoleSubjects.USER_OWN))
          )
        }
      >
        <Input
          id="name"
          required
          isInvalid={Boolean(errors.name && touched.name)}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={
            !(
              ability.can(RoleActions.UPDATE, RoleSubjects.USER) ||
              (isProfileEditing &&
                ability.can(RoleActions.UPDATE, RoleSubjects.USER_OWN))
            )
          }
        />
      </FormField>

      <FormField
        id="email"
        label={t('forms:fields.email')}
        error={errors.email && touched.email ? errors.email : ''}
        readOnly
      >
        <Input
          id="email"
          readOnly
          required
          isInvalid={Boolean(errors.email && touched.email)}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>

      <FormField
        id="role"
        label={t('forms:fields.userRole')}
        error={errors.role && touched.role ? errors.role : ''}
        readOnly={
          isProfileEditing ||
          !ability.can(RoleActions.UPDATE, RoleSubjects.USER)
        }
      >
        <UserRolesSelect
          id="role"
          name="role"
          onChange={(v) => {
            setFieldValue('role', v.value);
          }}
          onBlur={handleBlur}
          value={values.role}
          isDisabled={
            isProfileEditing ||
            !ability.can(RoleActions.UPDATE, RoleSubjects.USER)
          }
        />
      </FormField>

      {renderPasswords()}
    </form>
  );
};

export default BaseForm;
