import { useQuery } from '@tanstack/react-query';

import { mockResponse } from 'features/lib';
import type {
  Company,
  InviteRequest,
  RegisterResponse,
  CompanyUpdateRequest,
} from 'features/types';

import { StatusEnum, CompanyResponse } from 'features/types/openapi';
import { apiClient } from 'features/api';

export const getCompanies = async () => {
  // TODO: use real API request here
  const res = await mockResponse<Company[]>([
    {
      contact_email: 'example1@gmail.com',
      contact_phone: '+996473657',
      website: 'Upwork GmbH',
      created_by_user_id: 'uesr Id',
      id: '1',
      name: 'Upwork GmbH',
      status: StatusEnum.ACTIVE,
    },
    {
      id: '2',
      name: 'Penta Bank',
      contact_email: 'test@test.com',
      contact_phone: '+996473657',
      website: 'Upwork GmbH',
      created_by_user_id: 'uesr Id',
      status: StatusEnum.ACTIVE,
    },
    {
      id: '3',
      name: 'Unicredit',
      contact_email: '',
      contact_phone: '+996473657',
      website: 'Upwork GmbH',
      created_by_user_id: 'uesr Id',
      status: StatusEnum.DELETED,
    },
  ]);

  return res || [];
};

export const getCompanyById = async (id: string) => {
  const res = await apiClient.get<Company>(`companies/${id}`);

  return res.data || null;
};

export const useCompanies = () => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: () => getCompanies(),
  });
};

export const useCompany = (id?: string) => {
  return useQuery({
    queryKey: ['companies', id],
    queryFn: () => (id ? getCompanyById(id) : null),
    enabled: !!id,
  });
};

export const inviteCompanyUser = async (data: InviteRequest) => {
  const res = await apiClient.post<RegisterResponse>(
    'companies/invitation',
    data
  );

  return res.data;
};

export const updateCompanyLogo = async (id: string, data: FormData) => {
  const res = await apiClient.patch<CompanyResponse>(
    `companies/${id}/logo`,
    data
  );
  return res.data;
};

export const updateCompanyById = async (
  id: string,
  data: CompanyUpdateRequest
) => {
  const res = await apiClient.patch<CompanyResponse>(`companies/${id}`, data);
  return res.data;
};

export const deleteCompanyById = async (id: string) => {
  const res = await apiClient.delete(`companies/${id}`);

  return res.data || null;
};
