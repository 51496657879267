export const validatePassword = (value?: string) => {
  if (!value) {
    return true;
  }

  const isValid =
    /[0-9]+/.test(value) &&
    /[A-Z]+/.test(value) &&
    /[a-z]+/.test(value) &&
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
  return isValid;
};
