import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useLocalStorage } from 'react-use';
import { AUTH_TOKEN_STORAGE_KEY } from 'features/app/consts';
import {
  apiClient,
  queryClient,
  removeHeaderToken,
  setHeaderToken,
} from 'features/api';
import { AxiosError } from 'axios';
import { useInvalidateToken } from './queries';

interface AuthContextProps {
  token: string | undefined;
  isAuth: boolean;
  login: (token: string) => void;
  logout: () => void;
  confirmationEmail?: string;
  confirmationEmailToken?: string;
  setConfirmationEmail: (email?: string) => void;
  setConfirmationEmailToken: (token?: string) => void;
  resetPasswordData: ResetPasswordData | null;
  setResetPasswordData: (data: ResetPasswordData | null) => void;
}

interface initialValues {
  isAuth?: boolean;
  token?: string;
}

interface ResetPasswordData {
  token: string;
  email: string;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const AuthContextProvider = ({
  initialValues,
  children,
}: {
  initialValues?: initialValues;
  children: ReactNode;
}) => {
  const [isAuth, setIsAuth] = useState<boolean>(initialValues?.isAuth || false);
  const [token, setToken, removeToken] = useLocalStorage<string | undefined>(
    AUTH_TOKEN_STORAGE_KEY,
    initialValues?.token || undefined
  );
  // TODO confirmationEmail and confirmationEmailToken could be removed entirely
  const [confirmationEmail, setConfirmationEmail] = useState<
    string | undefined
  >(undefined);
  const [confirmationEmailToken, setConfirmationEmailToken] = useState<
    string | undefined
  >(undefined);
  const [resetPasswordData, setResetPasswordData] =
    useState<ResetPasswordData | null>(null);
  const invalidateTokenMutation = useInvalidateToken();

  useEffect(() => {
    if (token) {
      login(token);
    }
  }, []);

  useEffect(() => {
    apiClient.interceptors.response.use(
      (response: unknown) => response,
      (error: AxiosError) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        const errorMessage = error.response?.data?.error?.message;

        const { status } = error.response;

        if (status === 401 || errorMessage === 'Signature has expired') {
          logout();
        }

        return Promise.reject(error);
      }
    );
  });

  const login = (token: string) => {
    setToken(token);
    setHeaderToken(token);
    setIsAuth(true);
  };

  const logout = async () => {
    await invalidateTokenMutation.mutateAsync();

    queryClient.clear();
    removeToken();
    removeHeaderToken();
    setIsAuth(false);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuth,
        login,
        logout,
        confirmationEmail,
        confirmationEmailToken,
        setConfirmationEmail,
        setConfirmationEmailToken,
        resetPasswordData,
        setResetPasswordData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};
