import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { Button, FormField, Input, PasswordInput, Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import HelperLink from 'features/auth/Layout/HelperLink';
import { ROUTES } from 'features/app/consts';

import styles from '../styles.module.scss';

export type FormValues = {
  email: string;
  fullName: string;
  companyName: string;
  password: string;
  repeatPassword: string;
  companyWebsite: string;
};

interface BaseFormProps extends FormikProps<FormValues> {
  emailUsedError: boolean;
  emailInputRef: React.RefObject<HTMLInputElement>;
  token?: string;
  companyName?: string;
}

const BaseForm = (props: BaseFormProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { t } = useTranslation();

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    isValid,
    dirty,
    handleBlur,
    emailUsedError,
    emailInputRef,
    token,
    companyName,
  } = props;

  let isCreateOwnerAccount = true;
  let isCreateInvitationAccount = true;

  if (!token && isValid && dirty) {
    isCreateOwnerAccount = false;
  } else isCreateOwnerAccount = true;
  if (
    token &&
    !errors.fullName &&
    !errors.password &&
    !errors.repeatPassword &&
    values.fullName &&
    values.password &&
    values.repeatPassword
  ) {
    isCreateInvitationAccount = false;
  } else isCreateInvitationAccount = true;

  return (
    <div>
      <form method="post" onSubmit={handleSubmit}>
        <FormField
          id="companyName"
          label={t('forms:fields.companyName')}
          readOnly={!!token}
          error={
            errors.companyName && touched.companyName ? errors.companyName : ''
          }
        >
          <Input
            id="companyName"
            required
            isInvalid={Boolean(errors.companyName && touched.companyName)}
            disabled={!!companyName}
            value={companyName ? companyName : values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>
        {token ? null : (
          <>
            <FormField
              id="companyWebsite"
              label={t('forms:fields.companyWebsite')}
              error={
                errors.companyWebsite && touched.companyWebsite
                  ? errors.companyWebsite
                  : ''
              }
            >
              <Input
                id="companyWebsite"
                required
                isInvalid={Boolean(
                  errors.companyWebsite && touched.companyWebsite
                )}
                disabled={!!companyName}
                value={values.companyWebsite}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
          </>
        )}
        <FormField
          id="fullName"
          label={t('forms:fields.fullName')}
          error={errors.fullName && touched.fullName ? errors.fullName : ''}
        >
          <Input
            id="fullName"
            required
            isInvalid={Boolean(errors.fullName && touched.fullName)}
            value={values.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        {token ? null : (
          <FormField
            id="email"
            label={t('forms:fields.email')}
            error={
              emailUsedError ? (
                <>
                  {t('signup:errors.emailUsed1')}
                  <Link to={ROUTES.signin}>{t('signup:signin')}</Link>
                  {t('signup:errors.emailUsed2')}
                </>
              ) : errors.email && touched.email ? (
                errors.email
              ) : (
                ''
              )
            }
          >
            <Input
              inputRef={emailInputRef}
              id="email"
              required
              isInvalid={Boolean(errors.email && touched.email)}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        )}
        <FormField
          id="password"
          label={t('forms:fields.password')}
          error={
            errors.password && touched.password
              ? t('forms:invalidPassword')
              : ''
          }
          text={t('forms:passwordTip')}
        >
          <PasswordInput
            id="password"
            required
            isInvalid={Boolean(errors.password && touched.password)}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        <FormField
          id="repeatPassword"
          label={t('forms:fields.repeatPassword')}
          error={
            errors.repeatPassword && touched.repeatPassword
              ? errors.repeatPassword
              : ''
          }
        >
          <PasswordInput
            id="repeatPassword"
            required
            isInvalid={Boolean(errors.repeatPassword && touched.repeatPassword)}
            value={values.repeatPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormField>

        <Text color="grey">
          {t('signup:acceptTerms')}
          <a
            href={'https://monite.com/data-privacy/'}
            target="_blank"
            className={styles.termsLink}
            rel="noreferrer"
          >
            {t('signup:termsAndConditions')}
          </a>
          .
        </Text>

        {isSubmitting ? (
          <Button type="submit" disabled isLoading />
        ) : (
          <Button
            type="submit"
            disabled={isCreateOwnerAccount && isCreateInvitationAccount}
            text={t('signup:submit')}
            tooltip={
              !dirty || !isValid
                ? {
                    tip: t('signup:submitTip'),
                    effect: 'float',
                  }
                : undefined
            }
          />
        )}

        <HelperLink
          text={t('signup:signinText')}
          linkUrl={ROUTES.signin}
          linkText={t('signup:signinLink')}
        />
      </form>
    </div>
  );
};

export default BaseForm;
