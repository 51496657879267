/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AllowedCountriesNames {
    AFGHANISTAN = 'Afghanistan',
    _LAND_ISLANDS = 'Åland Islands',
    ALBANIA = 'Albania',
    ALGERIA = 'Algeria',
    AMERICAN_SAMOA = 'American Samoa',
    ANDORR_A = 'AndorrA',
    ANGOLA = 'Angola',
    ANGUILLA = 'Anguilla',
    ANTARCTICA = 'Antarctica',
    ANTIGUA_AND_BARBUDA = 'Antigua and Barbuda',
    ARGENTINA = 'Argentina',
    ARMENIA = 'Armenia',
    ARUBA = 'Aruba',
    AUSTRALIA = 'Australia',
    AUSTRIA = 'Austria',
    AZERBAIJAN = 'Azerbaijan',
    BAHAMAS = 'Bahamas',
    BAHRAIN = 'Bahrain',
    BANGLADESH = 'Bangladesh',
    BARBADOS = 'Barbados',
    BELARUS = 'Belarus',
    BELGIUM = 'Belgium',
    BELIZE = 'Belize',
    BENIN = 'Benin',
    BERMUDA = 'Bermuda',
    BHUTAN = 'Bhutan',
    BOLIVIA = 'Bolivia',
    BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
    BOTSWANA = 'Botswana',
    BOUVET_ISLAND = 'Bouvet Island',
    BRAZIL = 'Brazil',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'British Indian Ocean Territory',
    BRUNEI_DARUSSALAM = 'Brunei Darussalam',
    BULGARIA = 'Bulgaria',
    BURKINA_FASO = 'Burkina Faso',
    BURUNDI = 'Burundi',
    CAMBODIA = 'Cambodia',
    CAMEROON = 'Cameroon',
    CANADA = 'Canada',
    CANARY_ISLANDS = 'Canary Islands',
    CAPE_VERDE = 'Cape Verde',
    CAYMAN_ISLANDS = 'Cayman Islands',
    CENTRAL_AFRICAN_REPUBLIC = 'Central African Republic',
    CEUTA_MELILLA = 'Ceuta, Melilla',
    CHAD = 'Chad',
    CHILE = 'Chile',
    CHINA = 'China',
    CHRISTMAS_ISLAND = 'Christmas Island',
    COCOS_KEELING_ISLANDS = 'Cocos (Keeling) Islands',
    COLOMBIA = 'Colombia',
    COMOROS = 'Comoros',
    CONGO = 'Congo',
    CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'Congo, The Democratic Republic of the',
    COOK_ISLANDS = 'Cook Islands',
    COSTA_RICA = 'Costa Rica',
    COTE_D_IVOIRE = 'Cote D\'Ivoire',
    CROATIA = 'Croatia',
    CUBA = 'Cuba',
    CYPRUS = 'Cyprus',
    CZECH_REPUBLIC = 'Czech Republic',
    DENMARK = 'Denmark',
    DJIBOUTI = 'Djibouti',
    DOMINICA = 'Dominica',
    DOMINICAN_REPUBLIC = 'Dominican Republic',
    ECUADOR = 'Ecuador',
    EGYPT = 'Egypt',
    EL_SALVADOR = 'El Salvador',
    EQUATORIAL_GUINEA = 'Equatorial Guinea',
    ERITREA = 'Eritrea',
    ESTONIA = 'Estonia',
    ETHIOPIA = 'Ethiopia',
    FALKLAND_ISLANDS_MALVINAS_ = 'Falkland Islands (Malvinas)',
    FAROE_ISLANDS = 'Faroe Islands',
    FIJI = 'Fiji',
    FINLAND = 'Finland',
    FRANCE = 'France',
    FRENCH_GUIANA = 'French Guiana',
    FRENCH_POLYNESIA = 'French Polynesia',
    FRENCH_SOUTHERN_TERRITORIES = 'French Southern Territories',
    GABON = 'Gabon',
    GAMBIA = 'Gambia',
    GEORGIA = 'Georgia',
    GERMANY = 'Germany',
    GHANA = 'Ghana',
    GIBRALTAR = 'Gibraltar',
    GREECE = 'Greece',
    GREENLAND = 'Greenland',
    GRENADA = 'Grenada',
    GUADELOUPE = 'Guadeloupe',
    GUAM = 'Guam',
    GUATEMALA = 'Guatemala',
    GUERNSEY = 'Guernsey',
    GUINEA = 'Guinea',
    GUINEA_BISSAU = 'Guinea-Bissau',
    GUYANA = 'Guyana',
    HAITI = 'Haiti',
    HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'Heard Island and Mcdonald Islands',
    HOLY_SEE_VATICAN_CITY_STATE_ = 'Holy See (Vatican City State)',
    HONDURAS = 'Honduras',
    HONG_KONG = 'Hong Kong',
    HUNGARY = 'Hungary',
    ICELAND = 'Iceland',
    INDIA = 'India',
    INDONESIA = 'Indonesia',
    IRAN_ISLAMIC_REPUBLIC_OF = 'Iran, Islamic Republic Of',
    IRAQ = 'Iraq',
    IRELAND = 'Ireland',
    ISLE_OF_MAN = 'Isle of Man',
    ISRAEL = 'Israel',
    ITALY = 'Italy',
    JAMAICA = 'Jamaica',
    JAPAN = 'Japan',
    JERSEY = 'Jersey',
    JORDAN = 'Jordan',
    KAZAKHSTAN = 'Kazakhstan',
    KENYA = 'Kenya',
    KIRIBATI = 'Kiribati',
    KOREA_DEMOCRATIC_PEOPLE_S_REPUBLIC_OF = 'Korea, Democratic People\'S Republic of',
    KOREA_REPUBLIC_OF = 'Korea, Republic of',
    KUWAIT = 'Kuwait',
    KYRGYZSTAN = 'Kyrgyzstan',
    LAO_PEOPLE_S_DEMOCRATIC_REPUBLIC = 'Lao People\'S Democratic Republic',
    LATVIA = 'Latvia',
    LEBANON = 'Lebanon',
    LESOTHO = 'Lesotho',
    LIBERIA = 'Liberia',
    LIBYAN_ARAB_JAMAHIRIYA = 'Libyan Arab Jamahiriya',
    LIECHTENSTEIN = 'Liechtenstein',
    LITHUANIA = 'Lithuania',
    LUXEMBOURG = 'Luxembourg',
    MACAO = 'Macao',
    MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF = 'Macedonia, The Former Yugoslav Republic of',
    MADAGASCAR = 'Madagascar',
    MALAWI = 'Malawi',
    MALAYSIA = 'Malaysia',
    MALDIVES = 'Maldives',
    MALI = 'Mali',
    MALTA = 'Malta',
    MARSHALL_ISLANDS = 'Marshall Islands',
    MARTINIQUE = 'Martinique',
    MAURITANIA = 'Mauritania',
    MAURITIUS = 'Mauritius',
    MAYOTTE = 'Mayotte',
    MEXICO = 'Mexico',
    MICRONESIA_FEDERATED_STATES_OF = 'Micronesia, Federated States of',
    MOLDOVA_REPUBLIC_OF = 'Moldova, Republic of',
    MONACO = 'Monaco',
    MONGOLIA = 'Mongolia',
    MONTSERRAT = 'Montserrat',
    MOROCCO = 'Morocco',
    MOZAMBIQUE = 'Mozambique',
    MYANMAR = 'Myanmar',
    NAMIBIA = 'Namibia',
    NAURU = 'Nauru',
    NEPAL = 'Nepal',
    NETHERLANDS = 'Netherlands',
    NETHERLANDS_ANTILLES = 'Netherlands Antilles',
    NEW_CALEDONIA = 'New Caledonia',
    NEW_ZEALAND = 'New Zealand',
    NICARAGUA = 'Nicaragua',
    NIGER = 'Niger',
    NIGERIA = 'Nigeria',
    NIUE = 'Niue',
    NORFOLK_ISLAND = 'Norfolk Island',
    NORTHERN_MARIANA_ISLANDS = 'Northern Mariana Islands',
    NORWAY = 'Norway',
    OMAN = 'Oman',
    PAKISTAN = 'Pakistan',
    PALAU = 'Palau',
    PALESTINIAN_TERRITORY_OCCUPIED = 'Palestinian Territory, Occupied',
    PANAMA = 'Panama',
    PAPUA_NEW_GUINEA = 'Papua New Guinea',
    PARAGUAY = 'Paraguay',
    PERU = 'Peru',
    PHILIPPINES = 'Philippines',
    PITCAIRN = 'Pitcairn',
    POLAND = 'Poland',
    PORTUGAL = 'Portugal',
    PUERTO_RICO = 'Puerto Rico',
    QATAR = 'Qatar',
    REUNION = 'Reunion',
    ROMANIA = 'Romania',
    RUSSIAN_FEDERATION = 'Russian Federation',
    RWANDA = 'RWANDA',
    SAINT_HELENA = 'Saint Helena',
    SAINT_KITTS_AND_NEVIS = 'Saint Kitts and Nevis',
    SAINT_LUCIA = 'Saint Lucia',
    SAINT_PIERRE_AND_MIQUELON = 'Saint Pierre and Miquelon',
    SAINT_VINCENT_AND_THE_GRENADINES = 'Saint Vincent and the Grenadines',
    SAMOA = 'Samoa',
    SAN_MARINO = 'San Marino',
    SAO_TOME_AND_PRINCIPE = 'Sao Tome and Principe',
    SAUDI_ARABIA = 'Saudi Arabia',
    SENEGAL = 'Senegal',
    SERBIA_AND_MONTENEGRO = 'Serbia and Montenegro',
    SEYCHELLES = 'Seychelles',
    SIERRA_LEONE = 'Sierra Leone',
    SINGAPORE = 'Singapore',
    SLOVAKIA = 'Slovakia',
    SLOVENIA = 'Slovenia',
    SOLOMON_ISLANDS = 'Solomon Islands',
    SOMALIA = 'Somalia',
    SOUTH_AFRICA = 'South Africa',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'South Georgia and the South Sandwich Islands',
    SPAIN = 'Spain',
    SRI_LANKA = 'Sri Lanka',
    SUDAN = 'Sudan',
    SURINAME = 'Suriname',
    SVALBARD_AND_JAN_MAYEN = 'Svalbard and Jan Mayen',
    SWAZILAND = 'Swaziland',
    SWEDEN = 'Sweden',
    SWITZERLAND = 'Switzerland',
    SYRIAN_ARAB_REPUBLIC = 'Syrian Arab Republic',
    TAIWAN_PROVINCE_OF_CHINA = 'Taiwan, Province of China',
    TAJIKISTAN = 'Tajikistan',
    TANZANIA_UNITED_REPUBLIC_OF = 'Tanzania, United Republic of',
    THAILAND = 'Thailand',
    TIMOR_LESTE = 'Timor-Leste',
    TOGO = 'Togo',
    TOKELAU = 'Tokelau',
    TONGA = 'Tonga',
    TRINIDAD_AND_TOBAGO = 'Trinidad and Tobago',
    TUNISIA = 'Tunisia',
    TURKEY = 'Turkey',
    TURKMENISTAN = 'Turkmenistan',
    TURKS_AND_CAICOS_ISLANDS = 'Turks and Caicos Islands',
    TUVALU = 'Tuvalu',
    UGANDA = 'Uganda',
    UKRAINE = 'Ukraine',
    UNITED_ARAB_EMIRATES = 'United Arab Emirates',
    UNITED_KINGDOM = 'United Kingdom',
    UNITED_STATES = 'United States',
    UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'United States Minor Outlying Islands',
    URUGUAY = 'Uruguay',
    UZBEKISTAN = 'Uzbekistan',
    VANUATU = 'Vanuatu',
    VENEZUELA = 'Venezuela',
    VIET_NAM = 'Viet Nam',
    VIRGIN_ISLANDS_BRITISH = 'Virgin Islands, British',
    VIRGIN_ISLANDS_U_S_ = 'Virgin Islands, U.S.',
    WALLIS_AND_FUTUNA = 'Wallis and Futuna',
    WESTERN_SAHARA = 'Western Sahara',
    YEMEN = 'Yemen',
    ZAMBIA = 'Zambia',
    ZIMBABWE = 'Zimbabwe',
}