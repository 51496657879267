import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation, TFunction } from 'react-i18next';

import BaseForm, { FormValues } from './Form';
import { useRestorePasswordByEmail } from 'features/auth/queries';

const getValidationSchema = ({ t }: { t: TFunction }) => {
  const validators = {
    email: Yup.string()
      .email(t('forms:invalidEmail'))
      .required(t('forms:invalidEmail')),
  };

  return Yup.object().shape(validators);
};

type FormProps = {
  onSubmit: (email: string) => void;
};

const Form = (props: FormProps) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const restorePasswordMutation = useRestorePasswordByEmail();

  const onFormSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    const res = await restorePasswordMutation.mutateAsync({
      email: formData.email,
    });

    if (res?.success) {
      onSubmit(formData.email);
    } else {
      formHelpers.setFieldError('email', res?.error || t('forms:invalidEmail'));
    }
  };

  const renderForm = (props: FormikProps<FormValues>) => {
    return <BaseForm {...props} />;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: '' }}
      onSubmit={onFormSubmit}
      validationSchema={getValidationSchema({ t })}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;
