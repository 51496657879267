import React, { useState } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { inviteCompanyUser } from 'features/companies/api';
import type { InviteRequest } from 'features/types';
import { useModalContext } from 'features/app/Modals/ModalContext';

import BaseForm, { FormValues } from './Form';

const ModalInvite = () => {
  const { t } = useTranslation();
  const { setModal } = useModalContext();

  const [emailUsedError, setEmailUsedError] = useState(false);

  const client = useQueryClient();
  const inviteUserMutation = useMutation({
    mutationFn: (data: InviteRequest) => inviteCompanyUser(data),
    onSuccess: (data) => {
      client.invalidateQueries({ queryKey: ['users'] });
      if (data && data.invited_users) {
        toast(
          t('users:invite.sent', { count: data.invited_users?.length || 0 })
        );
      }

      setModal(null);
    },
    onError: (err: any) => {
      //TODO: backend issue - to add error for each user
      if (
        // TODO: in future use error statuses here
        err?.response?.data?.error?.message ===
        'User with this email already exists'
      ) {
        setEmailUsedError(true);
      }
    },
  });

  const onSubmit = async (
    formData: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    setEmailUsedError(false);

    const users = formData.users.map((user) => ({
      email: user.email,
      role_id: user.role,
    }));
    inviteUserMutation.mutate({ users });
  };

  const getValidationSchema = () => {
    const validators = {
      users: Yup.array(
        Yup.object({
          email: Yup.string()
            .email(t('forms:invalidEmail'))
            .required(t('forms:required')),
          role: Yup.string().required(t('forms:required')),
        })
      ),
    };

    return Yup.object().shape(validators);
  };

  const resolveInitialValues = () => {
    return {
      users: [
        {
          email: '',
          role: '',
        },
      ],
    };
  };

  return (
    <Formik
      enableReinitialize
      initialValues={resolveInitialValues()}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
    >
      {(props: FormikProps<FormValues>) => (
        <BaseForm emailUsedError={emailUsedError} {...props} />
      )}
    </Formik>
  );
};

export default ModalInvite;
