import React, { useState } from 'react';
import { Button, Text } from '@monite/ui';
import { useTranslation } from 'react-i18next';

import AuthLayout from 'features/auth/Layout';
import useCountdown from 'features/auth/hooks/useCountdown';

import Form from './Form';
import styles from './styles.module.scss';
import { useRestorePasswordByEmail } from '../queries';

type PageRestoreSentProps = {
  email: string;
};
const PageRestoreSent = ({ email }: PageRestoreSentProps) => {
  const { timeLeft, setTimeLeft } = useCountdown(30);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const restorePasswordByEmailMutation = useRestorePasswordByEmail();

  const onClickSendAgain = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    setHasError(false);

    const res = await restorePasswordByEmailMutation.mutateAsync({
      email,
    });

    if (!res) {
      setHasError(true);
    } else {
      setTimeLeft(30);
    }
  };

  return (
    <AuthLayout withContactsLink title={t('forgot2:title')}>
      <h2>{t('forgot2:header')}</h2>
      <div>
        <Text>{t('forgot2:text', { email })}</Text>
        <Button
          className={styles.button}
          text={
            t('resend:tip') && timeLeft
              ? t('resend:resendInSeconds', { timeLeft })
              : t('resend:resend')
          }
          onClick={timeLeft ? undefined : onClickSendAgain}
        />
        {hasError ? (
          <Text size="small" color="red">
            {t('forgot2:somethingWrong')}
          </Text>
        ) : null}
      </div>
    </AuthLayout>
  );
};

const PageRestore = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const onSubmit = (email: string) => {
    setEmail(email);
  };

  if (email) {
    return <PageRestoreSent email={email} />;
  }

  return (
    <AuthLayout title={t('forgot:title')}>
      <h2>{t('forgot:title')}</h2>
      <div className={styles.content}>
        <Text>{t('forgot:text')}</Text>
        <Form onSubmit={onSubmit} />
      </div>
    </AuthLayout>
  );
};

export default PageRestore;
