export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';
export const ROLE_BUSINESS = 'business';
export const ROLE_DEVELOPER = 'developer';
export const ROLE_OWNER = 'owner';

export const ROLES = {
  admin: ROLE_ADMIN,
  user: ROLE_USER,
  business: ROLE_BUSINESS,
  developer: ROLE_DEVELOPER,
  owner: ROLE_OWNER,
};
