import { Button, Card, Table } from '@monite/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'assets/icons/plus2.svg';

import Layout, { PageHeader, PageContent } from 'features/app/Layout';
import GlobalSpinner from 'features/app/GlobalSpinner';
import { ROUTES } from 'features/app/consts';
import { ModalKeys } from 'features/app/Modals';
import { useModalContext } from 'features/app/Modals/ModalContext';
import { useProjectQuery } from 'features/projects/api';
import { useApiKeys } from 'features/apikeys/api';

import ApiKeyRow from './ApiKeyRow';
import styles from './styles.module.scss';

const PageApiKeys = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setModal } = useModalContext();

  const { id } = useParams();

  const { isLoading, data: project } = useProjectQuery(id);

  useEffect(() => {
    if ((!isLoading && !project) || !id) {
      navigate(ROUTES.projects);
    }
  }, [isLoading, project]);

  const onClickNewApiKey = async () => {
    id &&
      setModal({
        key: ModalKeys.CREATE_API_KEY_MODAL,
        props: { id },
      });
  };

  const { data: apiKeys, isLoading: isLoadingApiKeys } = useApiKeys(id!);
  if ((!project && isLoading) || isLoadingApiKeys) {
    return <GlobalSpinner />;
  }

  if (!project) {
    return null;
  }

  return (
    <Layout title={t('apikeys:list.title')}>
      <PageHeader
        title={t('apikeys:list.title')}
        breadcrumbs={[
          {
            title: t('apikeys:list.back'),
            link: `/projects`,
          },
          {
            title: project.name,
            link: '',
          },
        ]}
      >
        {!!apiKeys?.secrets.length && (
          <Button
            onClick={onClickNewApiKey}
            leftIcon={<PlusIcon width={24} height={24} />}
            text={t('apikeys:list.new')}
          />
        )}
      </PageHeader>
      <PageContent>
        <Card className={styles.card}>
          {apiKeys?.secrets.length ? (
            <div className={styles.table}>
              <Table>
                <thead>
                  <tr>
                    <th className={styles.keyName}>
                      {t('tables:columns.clientId')}
                    </th>
                    <th className={styles.keyName}>
                      {t('tables:columns.secret')}
                    </th>
                    <th className={styles.status}>
                      {t('tables:columns.status')}
                    </th>
                    <th className={styles.date}>
                      {t('tables:columns.created')}
                    </th>
                    <th className={styles.date}>
                      {t('tables:columns.lastUsed')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.secrets?.map((key) => (
                    <ApiKeyRow key={key.id} data={key} />
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className={styles.empty}>
              <h2>{t('apikeys:empty.title')}</h2>
              <div>{t('apikeys:empty.text')}</div>
              <Button
                onClick={onClickNewApiKey}
                leftIcon={<PlusIcon width={24} height={24} />}
                text={t('apikeys:list.new')}
              />
            </div>
          )}
        </Card>
      </PageContent>
    </Layout>
  );
};

export default PageApiKeys;
