import React from 'react';
import { createRoot } from 'react-dom/client';
import qs from 'query-string';

import { init as i18nInit } from 'features/i18n';
import { SCREEN_TEST_MODE } from 'features/app/consts';
import Root from './Root';

import './reset.scss';
import './index.scss';
import 'assets/fonts/Faktum/font.css';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.REACT_APP_TESTMODE === SCREEN_TEST_MODE) {
  const query = qs.parse(window.location.search || '');

  import(`tests/states/${query.state || 'base'}.js`).then(
    async ({ default: state }) => {
      await i18nInit();
      console.log('testing state...', state);
      root.render(<Root initialState={state.state} initialData={state.data} />);
    }
  );
} else {
  (async function () {
    await i18nInit();

    root.render(<Root />);
  })();
}
